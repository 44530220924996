import { Component, OnInit } from '@angular/core';
import { AjaxService } from '../services/ajax.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-academics',
  templateUrl: './academics.component.html',
  styleUrls: ['./academics.component.scss']
})
export class AcademicsComponent implements OnInit {

  resources: any[] = []
  constructor(private _ajaxService: AjaxService, private _router: Router) { }

  ngOnInit(): void {
    this.getResources()
  }

  getResources() {
    const request = {
      parentId : 1
    } 
    this._ajaxService.post('dashboard/getResourcesOnParentId', request).then((response: any) => {
      if (response.statusCode == 200) {
        this.resources = response.data;
      }
    });
  }

  handleRedirect(path : string) {
    if (path != '' && path != null && path != undefined) {
      if (path.startsWith('/') || !path.startsWith('http')) {
        this._router.navigate([path])
      } else {
        window.open(path, "_blank");
      }
    }
  }

}
