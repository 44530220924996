<div class="container-fluid">
    <div class="row m-0 mt-3">
        <div class="col-md-6">
            <ng-container *ngIf="uploadUserData">
                <div class="input-group">
                    <div class="system-head prmd pr-2">Upload Users Data</div>
                    <input class="impbgr form-control" type="file" (change)="onFileChange($event, 'usersData')" #inputUserExcel
                        accept=".xlsx">
                    <button type="button" (click)="uploadExcelFile('usersData')" class="btn btn-primary">Upload</button>
                </div>
            </ng-container>
            <ng-container *ngIf="uploadCSIPData">
                <div class="input-group">
                    <div class="system-head prmd pr-2">Upload CSIP Data</div>
                    <input class="impbgr form-control" type="file" (change)="onFileChange($event, 'csipData')" #inputCSIPExcel
                        accept=".xlsx">
                    <button type="button" (click)="uploadExcelFile('csipData')" class="btn btn-primary">Upload</button>
                </div>
            </ng-container>
        </div>
        <div *ngIf="uploadUserData" class="col-md-3">
            <input
            type="text" id="userSearchValue"
            placeholder="Search by user First Name, Last Name etc..."
            class="form-input-my"
            (keyup)="applyFilter($event, 'user')"
            #input
            />
        </div>
        <div *ngIf="uploadCSIPData" class="col-md-3 p-0">
            <input
            type="text"
            placeholder="Search by school name"
            class="form-input-my"
            (keyup)="applyFilter($event, 'csip')"
            #input
            />
        </div>
        <div class="col-md-3">
            <select class="form-select" [(ngModel)]="uploadType" (change)="changeDataUpload()">
                <option value="usersData">Users Data</option>
                <option value="csipData">CSIP Data</option>
            </select>
        </div>
    </div>
    <div class="row m-0">
        <div class="text-left col-md-12 mt-2" *ngIf="uploadUserData">
            <div>
                <label class="emaillabel" style="padding: 5px 10px; font-size: 13px;">
                    <input class="form-input" type="checkbox" value="Admin" (change)="filterForUserTable($event)"> <span
                        class="pose123">Admin</span>
                </label>
                <label class="emaillabel" style="padding: 5px 10px; font-size: 13px;">
                    <input class="form-input" type="checkbox" value="Principal"
                        (change)="filterForUserTable($event)"><span class="pose123">Principal</span>
                </label>
                <label class="emaillabel" style="padding: 5px 10px; font-size: 13px;">
                    <input class="form-input" type="checkbox" value="Guest"
                        (change)="filterForUserTable($event)"><span class="pose123">Guest</span>
                </label>
            </div>
            <div class="row m-0">
                <div class="bg-info text-white widthm-320" *ngIf="userTableData.length == 0">No Recors Found</div>
                <div *ngIf="userTableData.length != 0" class="col-md-12 p-0">
                    <div class="table-responsive tmdscroll">
                        <!--Table-->
                        <table class="table t-textleft table-striped">

                            <!--Table head-->
                            <thead>
                                <tr>
                                    <th *ngFor="let col of userTableColumnOrder">{{col}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of getPaginatedData('user') let rowIndex = index">
                                    <td *ngFor="let col of userTableColumnOrder">{{ row[col] }}</td>
                                </tr>
                                <!-- <tr *ngFor="let user of userTableData">
                                    <td *ngFor="let col of userTableColumnOrder">{{ user[col] }}</td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="userTableData.length != 0" class="col-md-12 mt-3 mb-2 text-right p-0">
                        <nav aria-label="Page navigation example">
                            <div class="pagination float-right">
                                <button class="page-item page-link" *ngFor="let pageNumber of getPageNumbers('user')" (click)="setPage(pageNumber)">
                                    {{ pageNumber }}
                                </button>
                                <!-- <li class="page-item"><a class="page-link" href="#">Previous</a></li> -->
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!uploadUserData">
        <div class="row m-0 mt-2">
            <div class="bg-info text-white widthm-320" *ngIf="csipDataForTable.length == 0">No Recors Found</div>
            <div *ngIf="csipDataForTable.length != 0" class="col-md-12 p-0">
                <div class="table-responsive tmdscroll">
                    <table class="table table-striped">
                        <thead>
                          <tr>
                            <ng-container *ngFor="let parentColumn of tableData">
                              <th class="content-center" [attr.colspan]="parentColumn.childColumns.length"
                              >
                                {{ parentColumn.name }}
                              </th>
                            </ng-container>
                          </tr>
                          <tr>
                            <ng-container *ngFor="let parentColumn of tableData">
                              <ng-container *ngFor="let childColumn of parentColumn.childColumns">
                                <th class="content-center">{{ childColumn }}</th>
                              </ng-container>
                            </ng-container>
                          </tr>
                        </thead>
                        <tbody>
                            <!-- <tr *ngFor="let row of getPaginatedData() let rowIndex = index">
                                <ng-container *ngFor="let parentColumn of tableData; let colIndex = index">
                                  <ng-container *ngFor="let childColumn of parentColumn.childColumns">
                                    <td [ngClass]="getCellClass(tableData[colIndex].data[rowIndex][childColumn])">
                                        {{ tableData[colIndex].data[rowIndex][childColumn] }}
                                    </td>
                                  </ng-container>
                                </ng-container>
                            </tr> -->
                            <tr *ngFor="let row of getPaginatedData('csip') let rowIndex = index">
                                <ng-container *ngFor="let parentColumn of tableData; let colIndex = index">
                                  <ng-container *ngFor="let childColumn of parentColumn.childColumns">
                                    <td class="content-center" [ngClass]="getCellClass(row[childColumn])"
                                        [innerHTML]="updateCellValue(childColumn, row[childColumn])">
                                    </td>
                                  </ng-container>
                                </ng-container>
                            </tr>
                          </tbody>
                      </table> 
                </div>
            </div>                           
            <div *ngIf="csipDataForTable.length != 0" class="col-md-12 mt-0 mb-2 text-right p-0">
                <nav aria-label="Page navigation example">
                    <div class="pagination float-right">
                        <button class="page-item page-link" *ngFor="let pageNumber of getPageNumbers('csip')" (click)="setPage(pageNumber)">
                            {{ pageNumber }}
                        </button>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</div>