<section>
    <div class="container-fluid bg-colorgrey">
        <div class="row m-0">
            <div class="col-md-9">
                <div class="row m-0">
                    <div class="col-md-12">
                        <div class="text-left">

                            <ul class="ul-decial">
                                <span class="system-head">System-Wide Goals</span>
                                <li> 75% of students at each school will achieve annual typical growth on i-Ready
                                    Reading and
                                    Math assessments by 2024-25</li>
                                <li>75% of students at each school will “Strongly Agree” that “God is present in my
                                    life.” by 2024-25</li>
                                <li>Each school will be rated Strong on at least 3/5 Essentials, including Supportive
                                    Environment, at the conclusion of 2024-25</li>
                                <li>Each school will fully enroll its Kindergarten by 2024</li>
                                <li>Each school will operate within an approved budget</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-3">
                <div class="getlowest_filds">
                    <ul>
                        <li>
                            <span class="position-relative top15" (mouseleave)="closeOptions()">
                                <label>Select School</label><br />
                                <input type="text" class="comment_fild getlowest_fild fild_bg"
                                    [formControl]="searchText" (focus)="enableFilter = true" #searchInput />
                                <div class="search-lister" *ngIf="enableFilter">
                                    <ul>
                                        <li *ngFor="let school of filteredSchools | async" [value]="school.schoolId"
                                            (click)="selectSchool(school)">{{school.name}}</li>
                                    </ul>
                                </div>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-12 col-lg-9 pr-0">
                <div class="row m-0">
                    <div class="col-md-12 p-0">
                        <div class="row m-0">
                            <div class="col-md-12 col-lg-6 pl-0">
                                <div class="card min-height-290  mt-2 mb-2">
                                    <div class="card-body position-relative">
                                        <div class="d-flex mt-2">
                                            <div class="card-title font-weight-600">
                                                <a href="https://login.i-ready.com/" target="_blank">Academic
                                                    Performance Summary</a>
                                            </div>
                                            <div class="posabs12"
                                                [ngClass]="flags.academics > 0 ? 'orangemark' : 'greenmark'"
                                                [ngbTooltip]="flags.academics > 0 ? 'Goal not yet achieved' : 'Goal achieved'">
                                            </div>
                                        </div>
                                        <div class="content-page mt-0">
                                            <div class="sub-title mb-2">Progress toward annual typical growth… </div>
                                            <canvas width="400" height="200" #academicChart></canvas>
                                        </div>
                                    </div>
                                </div>
                                <div class="card min-height-290 mt-2 mb-2">
                                    <div class="card-body position-relative">
                                        <div class="d-flex mt-2">
                                            <div class="card-title font-weight-600"><a [href]="CIScorecards"
                                                    target="_blank">Catholic Identity Summary</a></div>
                                            <div class="posabs12"
                                                [ngClass]="flags.catholicIdentity > 0 ? 'orangemark' : 'greenmark'"
                                                [ngbTooltip]="flags.catholicIdentity > 0 ? 'Goal not yet achieved' : 'Goal achieved'">
                                            </div>
                                        </div>
                                        <div class="content-page">
                                            <canvas width="400" height="200" #identityChart></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 pl-0">
                                <div class="card min-height-290  mt-2 mb-2">
                                    <div class="card-body pt-155 position-relative">
                                        <div class="d-flex mt-2">
                                            <div class="card-title font-weight-600"><a
                                                    href="https://www.5-essentials.org/" target="_blank">5 Essentials
                                                    Performance Summary</a>
                                            </div>
                                            <div class="posabs12"
                                                [ngClass]="flags.essentials > 0 ? 'orangemark' : 'greenmark'"
                                                [ngbTooltip]="flags.essentials > 0 ? 'Goal not yet achieved' : 'Goal achieved'">
                                            </div>
                                        </div>
                                        <div class="content-page mt-0">
                                            <div class="sub-title mb-2">Overall Performance <span
                                                    [ngClass]="{'status-code-well-organized': essentials.overallPerformance == 'Well-Organized', 'status-code-organized': essentials.overallPerformance == 'Organized', 'status-code-moderate' : essentials.overallPerformance == 'Moderately Organized', 'status-code-partially-organized' : essentials.overallPerformance == 'Partially Organized', 'status-code-no-organized' : essentials.overallPerformance == 'Not Yet Organized', 'status-code-low' : essentials.overallPerformance == 'Low Response'}">{{essentials.overallPerformance}}</span>
                                            </div>
                                            <div class="boxer-ranger mt-2">
                                                <div class="boxer-20">Effective Leaders</div>
                                                <div class="boxer-20">Collaborative Teachers</div>
                                                <div class="boxer-20">Involved Families</div>
                                                <div class="boxer-20">Supportive Environment</div>
                                                <div class="boxer-20">Ambitious Instruction</div>
                                            </div>
                                            <div class="boxer-ranger firstbox">
                                                <div class="boxer-20"
                                                    [ngClass]="{'essentials-code-well-organized': essentials.effectiveLeaders == 'Very Strong', 'essentials-code-organized': essentials.effectiveLeaders == 'Strong', 'essentials-code-moderate' : essentials.effectiveLeaders == 'Neutral', 'essentials-code-partially-organized' : essentials.effectiveLeaders == 'Weak', 'essentials-code-no-organized' : essentials.effectiveLeaders == 'Very Weak', 'essentials-code-low' : essentials.effectiveLeaders == 'Low Response'}">
                                                    {{essentials.effectiveLeaders}}</div>
                                                <div class="boxer-20"
                                                    [ngClass]="{'essentials-code-well-organized': essentials.collaborativeTeachers == 'Very Strong', 'essentials-code-organized': essentials.collaborativeTeachers == 'Strong', 'essentials-code-moderate' : essentials.collaborativeTeachers == 'Neutral', 'essentials-code-partially-organized' : essentials.collaborativeTeachers == 'Weak', 'essentials-code-no-organized' : essentials.collaborativeTeachers == 'Very Weak', 'essentials-code-low' : essentials.collaborativeTeachers == 'Low Response'}">
                                                    {{essentials.collaborativeTeachers}}</div>
                                                <div class="boxer-20"
                                                    [ngClass]="{'essentials-code-well-organized': essentials.involvedFamilies == 'Very Strong', 'essentials-code-organized': essentials.involvedFamilies == 'Strong', 'essentials-code-moderate' : essentials.involvedFamilies == 'Neutral', 'essentials-code-partially-organized' : essentials.involvedFamilies == 'Weak', 'essentials-code-no-organized' : essentials.involvedFamilies == 'Very Weak', 'essentials-code-low' : essentials.involvedFamilies == 'Low Response'}">
                                                    {{essentials.involvedFamilies}}</div>
                                                <div class="boxer-20"
                                                    [ngClass]="{'essentials-code-well-organized': essentials.supportiveEnvironment == 'Very Strong', 'essentials-code-organized': essentials.supportiveEnvironment == 'Strong', 'essentials-code-moderate' : essentials.supportiveEnvironment == 'Neutral', 'essentials-code-partially-organized' : essentials.supportiveEnvironment == 'Weak', 'essentials-code-no-organized' : essentials.supportiveEnvironment == 'Very Weak', 'essentials-code-low' : essentials.supportiveEnvironment == 'Low Response'}">
                                                    {{essentials.supportiveEnvironment}}</div>
                                                <div class="boxer-20"
                                                    [ngClass]="{'essentials-code-well-organized': essentials.ambitiousInstruction == 'Very Strong', 'essentials-code-organized': essentials.ambitiousInstruction == 'Strong', 'essentials-code-moderate' : essentials.ambitiousInstruction == 'Neutral', 'essentials-code-partially-organized' : essentials.ambitiousInstruction == 'Weak', 'essentials-code-no-organized' : essentials.ambitiousInstruction == 'Very Weak', 'essentials-code-low' : essentials.ambitiousInstruction == 'Low Response'}">
                                                    {{essentials.ambitiousInstruction}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card min-height-290  mt-2 mb-2">
                                    <div class="card-body position-relative">
                                        <div class="d-flex mt-2">
                                            <div class="card-title font-weight-600">Enrollment Summary </div>
                                            <div class="posabs12"
                                                [ngClass]="flags.enrollment > 0 ? 'orangemark' : 'greenmark'"
                                                [ngbTooltip]="flags.enrollment > 0 ? 'Goal not yet achieved' : 'Goal achieved'">
                                            </div>
                                        </div>
                                        <div class="content-page">
                                            <canvas width="400" height="200" #enrollmentChart></canvas>
                                        </div>
                                        <div class="sub-title mt-center mt-1">
                                            Avg. Enrollment in K-2 = <span
                                                [ngClass]="averageEnrollment < 20 ? 'status-code-red-2' : ''">{{averageEnrollment}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-3 p-0">
                <div class="row m-0">
                    <div class="col-md-12 p-0">
                        <div class="card min-height-290 mt-2 mb-0">
                            <div class="card-body">
                                <div class="card-title font-weight-600">
                                    <a>Resources</a>
                                </div>
                                <div class="box-div">
                                    <div class="row m-0">
                                        <div class="col-md-12 p-0">
                                            <div class="bd-rbox mt-3">
                                                <div class="displpd" *ngFor="let item of resources;let i = index">
                                                    <div class="bluebooard" style="cursor: pointer;"
                                                        (click)="handleRedirect(item.redirectPath)">
                                                        <div class="blueimgs">
                                                            <img [src]="item.imageSrc">
                                                        </div>
                                                        <div class="text-center pb-2 bluewhite">{{item.displayText}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="box-div">
                                    <div class="d-flex mt-2">
                                        <span class="mr-2"
                                            [ngClass]="flags.academics > 0 ? 'orangemark' : 'greenmark'"></span>
                                        <div class="card-title font-weight-600 cursor-pointer"><a
                                                href="https://docs.google.com/spreadsheets/d/1NqG2JxPsTsu4-7cSpg2BWHFW4Hnh57eNDyYXgi4lVjs/preview"
                                                target="_blank">ACADEMICS</a></div>
                                    </div>
                                    <div class="content-box">
                                        <ul>
                                            <li *ngFor="let flag of academicFlags | keyvalue"
                                                [hidden]="flag.value == 'N'">{{flag.key}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="box-div">
                                    <div class="d-flex mt-2">
                                        <span class="mr-2"
                                            [ngClass]="flags.essentials > 0 ? 'orangemark' : 'greenmark'"></span>
                                        <div class="card-title font-weight-600 cursor-pointer"><a
                                                href="https://docs.google.com/spreadsheets/d/1GxmPM6EzdhdInuSkTR3ZLhTjj8_KR0sjUlMw2Osp_N0/preview"
                                                target="_blank">5 ESSENTIALS</a>
                                        </div>
                                    </div>
                                    <div class="content-box">
                                        <ul>
                                            <li *ngFor="let flag of essentialsFlags | keyvalue"
                                                [hidden]="flag.value == 'N'">{{flag.key}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="box-div">
                                    <div class="d-flex mt-2">
                                        <span class="mr-2"
                                            [ngClass]="flags.catholicIdentity > 0 ? 'orangemark' : 'greenmark'"></span>
                                        <div class="card-title font-weight-600 cursor-pointer"><a
                                                href="https://docs.google.com/spreadsheets/d/1Nl6i3rnpeoZ9JM0jwF2iAxDBg3CwLRxG4JLW474JjQ8/preview"
                                                target="_blank">CATHOLIC IDENTITY</a></div>
                                    </div>
                                    <div class="content-box">
                                        <ul>
                                            <li *ngFor="let flag of catholicIdentity | keyvalue"
                                                [hidden]="flag.value == 'N'">{{flag.key}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="box-div">
                                    <div class="d-flex mt-2">
                                        <span class="mr-2"
                                            [ngClass]="flags.enrollment > 0 ? 'orangemark' : 'greenmark'"></span>
                                        <div class="card-title font-weight-600 cursor-pointer"><a
                                                href="https://docs.google.com/spreadsheets/d/1YR3T2Gl9yNxcTK52V4hRv995XQAsSTaKQvDRt_ZvuyU/preview"
                                                target="_blank">ENROLLMENT</a></div>
                                    </div>
                                    <div class="content-box">
                                        <ul>
                                            <li *ngFor="let flag of enrollmentOperationsFlag | keyvalue"
                                                [hidden]="flag.value == 'N'">{{flag.key}}</li>
                                        </ul>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 p-0">
                        <div class="card min-height-290 mt-2 mb-0">
                            <div class="card-body">
                                <div class="card-title font-weight-600"><a>Blue Ribbon Calculator</a></div>
                                <div class="row m-0">
                                    <div class="col-md-12 p-0">
                                        <div class="ribin pt-5">
                                            <img src="../../assets/images/blueribbion.png">
                                        </div>
                                    </div>
                                    <!-- <div class="col-6 text-right">
                                        <div class="card-title font-weight-500">
                                            <a [href]="blueRibbon?.Fall" target="_blank">Fall 2022</a>
                                        </div>
                                    </div> -->
                                    <div class="col-12 text-center">
                                        <div class="card-title font-weight-500">
                                            <a [href]="blueRibbon?.Spring" target="_blank">Spring {{blueRibbon.year}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #pdfView let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"> </h4>
        <button type="button" class="close-button" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body" style="height: 540px;">
        <iframe src="assets/2023.24 CSIP resource.pdf" width="100%" height="100%" frameborder="0"></iframe>
    </div>
</ng-template>