import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AjaxService } from '../services/ajax.service';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

type FieldName = "readingGrowth" | "mathGrowth" | "stronglyAgree" | "effectiveLeaders" | "supportiveEnvironment" | "involvedFamilies" | "ambitiousInstruction" | "collaborativeTeachers";
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @ViewChild('academicChart') academicChart: any;
  @ViewChild('essentialsChart') essentialsChart: any;
  @ViewChild('catholicChart') catholicChart: any;
  @ViewChild('enrollmentChart') enrollmentChart: any;

  essentialChartData: any = {
    "supportiveEnvironment" : {"Very Weak" : 0, "Weak" : 0, "Neutral" : 0, "Strong" : 0, "Very Strong" : 0},
    "involvedFamilies" : {"Very Weak" : 0, "Weak" : 0, "Neutral" : 0, "Strong" : 0, "Very Strong" : 0},
    "ambitiousInstruction" : {"Very Weak" : 0, "Weak" : 0, "Neutral" : 0, "Strong" : 0, "Very Strong" : 0},
    "collaborativeTeachers" : {"Very Weak" : 0, "Weak" : 0, "Neutral" : 0, "Strong" : 0, "Very Strong" : 0},
    "effectiveLeaders" : {"Very Weak" : 0, "Weak" : 0, "Neutral" : 0, "Strong" : 0, "Very Strong" : 0},
  }

  academicChartData: any = {
    'lessThan40' : 0,
    '41to50' : 0,
    '51to60' : 0,
    '61to74' : 0,
    '75to99' : 0,
    '100+' : 0,
  }

  enrollmentChartData : any = {
    "0To100":0,
    "101To150":0,
    "151To200":0,
    "200To250":0,
    "251To300":0,
    "301To350":0,
    "351+":0,
  }

  catholicIdentityChartData: any = {
    'lessThan40' : 0,
    '41to50' : 0,
    '51to60' : 0,
    '61to74' : 0,
    '75+' : 0,
  }

  acaChart: any;
  essentialChart: any;
  catholicIdentityChart: any;
  enrollmentSummaryChart: any;
  numberOfSchoolsOrganized: Number = 0;
  numberOfSchoolsBelowAvg: Number = 0;
  currentYear: number = new Date().getFullYear();
  acadenicChartType : string = 'reading'
  academicData: any;
  essentialData: any;
  catholicIdentityData: any;
  enrollmentSummaryData: any;
  filterOptions: any[] = [];
  selectedFilterForAcademic: string = 'all';
  selectedFilterForEssentials: string = 'all';
  selectedFilterForCatholic: string = 'all';
  selectedFilterForEnrollment: string = 'all';
  constructor(private _ajaxService: AjaxService, private _userService: UserService, private _router: Router) {
    Chart.register(...registerables);
    Chart.register(ChartDataLabels);
  }

  ngOnInit(): void {
    this._userService.getUserDetails().subscribe((value: any) => {
      if (value != undefined && Object.keys(value).length > 0) {
        if (value.roleId != 5 && value.roleId != 1) {
          this._router.navigate(['/dashboard'])
        }
      }
    });
    this.getDataForWidgets()
  }

  ngAfterViewInit() {
    this.drawAcademicChart();
    this.drawEssentialsChart();
    this.drawCatholicIdentityChart();
    this.drawEnrollmentSummaryChart();
  }

  /**
   * @author Manjunath
   * @uses to draw Academic chart
   */
  drawAcademicChart() {
    const layoutOptions = {
      padding: {
        top: 30, 
      },
    };
    let canvas = this.academicChart.nativeElement;
    let ctx = canvas.getContext('2d');
    this.acaChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['<=40', '41-50', '51-60', '61-74', '75-99', '100 + Met'],
        datasets: [
          {
            label: 'No. of schools',
            data: [this.academicChartData.lessThan40, this.academicChartData['41to50'], this.academicChartData['51to60'], this.academicChartData['61to74'], this.academicChartData['75to99'], this.academicChartData['100+']],
            barThickness: 30,
            // barPercentage: 0.4,
            backgroundColor: ['#880303', '#e62e2d','#ee7d31', '#fdff02', '#7bd355', '#4fa716'],
            borderColor: ['#880303', '#e62e2d', '#ee7d31', '#fdff02', '#7bd355', '#4fa716'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        layout: layoutOptions,
        responsive: true,
        scales: {
          xAxis: {
            title: {
              display: true,
              text: '% of Students Meeting Annual Typical Growth',
            },
            grid: {
              display: false,
            },
          },
          // y: {
          //   display:false,
          //   title: {
          //     display: true,
          //     text: '% Students'
          //   },
          //   grid: {
          //     display: false
          //   }
          // },
          myScale: {
            display: false,
            type: 'logarithmic',
            position: 'left', // `axis` is determined by the position as `'y'`
          },
        },
        plugins: {
          legend: { display: false },
          datalabels: {
            formatter: (value, _ctx) => {
              return value;
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            padding: {
              top: 1,
            },
            labels: {
              title: {
                font: {
                  weight: 'bold',
                },
              },
            },
          },
        },
      },
    });
  }

  /**
   * @author Manjunath
   * @uses to draw Essentials Chart
   */
  drawEssentialsChart () {
    let canvas = this.essentialsChart.nativeElement;
    let ctx = canvas.getContext('2d');
    this.essentialChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["Supportive Environment", "Involved Families", "Ambitious Instruction", "Collaborative Teachers", "Effective Leaders"],
        datasets: [{
          barPercentage: 0.5,
          label: 'Very Weak',
          backgroundColor: "rgb(158, 3, 3)",
          data: [this.essentialChartData.supportiveEnvironment['Very Weak'], this.essentialChartData.involvedFamilies['Very Weak'], this.essentialChartData.ambitiousInstruction['Very Weak'], this.essentialChartData.collaborativeTeachers['Very Weak'], this.essentialChartData.effectiveLeaders['Very Weak']],
        }, {
          barPercentage: 0.5,
          label: 'Weak',
          backgroundColor: "#e62e2d",
          data: [this.essentialChartData.supportiveEnvironment['Weak'], this.essentialChartData.involvedFamilies['Weak'], this.essentialChartData.ambitiousInstruction['Weak'], this.essentialChartData.collaborativeTeachers['Weak'], this.essentialChartData.effectiveLeaders['Weak']],
        },{
          barPercentage: 0.5,
          label: 'Neutral',
          backgroundColor: "#fdff00",
          data: [this.essentialChartData.supportiveEnvironment['Neutral'], this.essentialChartData.involvedFamilies['Neutral'], this.essentialChartData.ambitiousInstruction['Neutral'], this.essentialChartData.collaborativeTeachers['Neutral'], this.essentialChartData.effectiveLeaders['Neutral']],
        }, {
          barPercentage: 0.5,
          label: 'Strong',
          backgroundColor: "#7bd355",
          data: [this.essentialChartData.supportiveEnvironment['Strong'], this.essentialChartData.involvedFamilies['Strong'], this.essentialChartData.ambitiousInstruction['Strong'], this.essentialChartData.collaborativeTeachers['Strong'], this.essentialChartData.effectiveLeaders['Strong']],
        }, {
          barPercentage: 0.5,
          label: 'Very Strong',
          backgroundColor: "#4fa716",
          data: [this.essentialChartData.supportiveEnvironment['Very Strong'], this.essentialChartData.involvedFamilies['Very Strong'], this.essentialChartData.ambitiousInstruction['Very Strong'], this.essentialChartData.collaborativeTeachers['Very Strong'], this.essentialChartData.effectiveLeaders['Very Strong']],
        }],
      },
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        indexAxis: 'y',
        scales: {
          xAxis: {
            stacked: true,
            grid: {
              display: false,
            }
          },
          yAxis: {
            stacked: true,
            grid: {
              display: false,
            }
          }
        },
        plugins: {
          title: {
            display: false,
            text: ''
          },
          legend: { display: false },
          datalabels: {
            display: false,
            // formatter: (value, ctx) => {
            //   return value + "%";
            // },
            color: 'black',
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              }
            }
          },
          tooltip: {
            callbacks: {
              label: (Item) => {
                return `${Item.dataset.label}: ${Item.formattedValue}%`
              }
            }
          }
        }
      },
    });
  }

  /**
   * @author Manjunath
   * @uses to draw catholic Identity Chart
   */
  drawCatholicIdentityChart(){
    const layoutOptions = {
      padding: {
        top: 30,
      },
    };
    let canvas = this.catholicChart.nativeElement;
    let ctx = canvas.getContext('2d');
    this.catholicIdentityChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['<40', '41-50', '51-60', '61-74', '75+'],
        datasets: [
          {
            label: 'No. of schools',
            data: [this.catholicIdentityChartData.lessThan40, this.catholicIdentityChartData['41to50'], this.catholicIdentityChartData['51to60'], this.catholicIdentityChartData['61to74'], this.catholicIdentityChartData['75+']],
            barThickness: 30,
            // barPercentage: 0.4,
            backgroundColor: ['#880303', '#e62e2d', '#e8e052', '#7bd355', '#4fa716'],
            borderColor: ['#880303', '#e62e2d', '#e8e052', '#7bd355', '#4fa716'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        layout: layoutOptions,
        responsive: true,
        scales: {
          xAxis: {
            title: {
              display: true,
              text: '% Strongly Agree that "God is present in my life"',
            },
            grid: {
              display: false,
            },
          },
          // y: {
          //   display:false,
          //   title: {
          //     display: true,
          //     text: '% Students'
          //   },
          //   grid: {
          //     display: false
          //   }
          // },
          myScale: {
            display: false,
            type: 'logarithmic',
            position: 'left', 
          },
        },
        plugins: {
          legend: { display: false },
          datalabels: {
            formatter: (value, _ctx) => {
              return value;
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            padding: {
              top: 1,
            },
            labels: {
              title: {
                font: {
                  weight: 'bold',
                },
              },
            },
          },
        },
      },
    });
  }

  /**
   * @author Manjunath
   * @uses to draw Enrollment chart
   */
  drawEnrollmentSummaryChart() {
    let canvas = this.enrollmentChart.nativeElement;
    let ctx = canvas.getContext('2d');
    this.enrollmentSummaryChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: ['0-100', '101-150', '151-200', '201-250', '251-300', '301-350', '351+'],
        datasets: [{
          label: 'No. of Schools',
          data: [this.enrollmentChartData['0To100'], this.enrollmentChartData['101To150'], this.enrollmentChartData['151To200'], this.enrollmentChartData['200To250'], this.enrollmentChartData['251To300'], this.enrollmentChartData['301To350'], this.enrollmentChartData['351+']],
          // barThickness: 20,
          barPercentage: 1,
          maxBarThickness: 35,
          backgroundColor: ['#4fa716'],
          borderColor: ['#4fa716'],
          borderWidth: 1,
        }],
      },
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        indexAxis: 'y',
        
        scales: {
          
          xAxis: {
            // stacked: true,
            ticks: {
              stepSize: 4,
            },
            grid: {
              display: true,
              color: '#000000',
              drawBorder: false,
              // tickColor: 'grey',
              // borderColor: '#000000'
            }
          },
          yAxis: {
            // stacked: true,
            grid: {
              display: true,
              color: '#000000',
              // tickColor: 'grey',
              // borderColor: '#000000'
            }
          }
        },
        plugins: {
          title: {
            display: false,
            text: ''
          },
          legend: { display: false},
          
          datalabels: {
            display: false,
            // formatter: (value, ctx) => {
            //   return value + "%";
            // },
            color: 'black',
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              }
            }
          },
          // tooltip: {
          //   callbacks: {
          //     label: (Item) => {
          //       return `${Item.dataset.label}: ${Item.formattedValue}%`
          //     }
          //   }
          // }
        }
      },
    });
  }

  /**
   * @author Manjunath
   * @uses to calculate the avarage for essentials chart.
   */
  calculatePercentageForEssentilas(data:any[], field: FieldName) {
    let levelCounts:any = {
      "Very Weak": 0,
      "Weak": 0,
      "Neutral": 0,
      "Strong": 0,
      "Very Strong": 0,
    };
    data.forEach(school => {
      for (const level in levelCounts) {
        if (school[field] === level) {
          levelCounts[level]++;
          break;
        }
      }
    });
    for (const levelCount in levelCounts) {
      const totalSchools = data.length;
      const percentage = (levelCounts[levelCount] / totalSchools) * 100;
      levelCounts[levelCount] = percentage.toFixed(0);
    }
    this.updateKeyValuesForEssential(levelCounts, field)
    // return levelCounts
  }

  /**
   * @author Manjunath
   * @uses to update the values of object essentialChartData.
   */
  updateKeyValuesForEssential(data:any, field: FieldName) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this.essentialChartData[field][key] = data[key];
      }
    }
  }

  /**
   * @author Manjunath
   * @uses to get data for Essential chart 
   */
  getDataForEssential(data:any[]) {
    const types: FieldName[] = ['effectiveLeaders', 'supportiveEnvironment', 'involvedFamilies', 'ambitiousInstruction', 'collaborativeTeachers']
    types.forEach((type) => {
      this.calculatePercentageForEssentilas(data, type);
    })
  };

  /**
   * @author Manjuath
   * @uses to calculate average percentage of schools data withing a threshold.
   */
  calculatePercentageOnThreshold(data:any[], minThreshold:Number, maxThreshold:Number, field: any) {
    let schoolsAboveThreshold = 0;
    data.forEach(school => {
      if (school[field] >= minThreshold && school[field] <= maxThreshold) {
        schoolsAboveThreshold++;
      }
    });
    if (field == 'readingGrowth' || field == 'mathGrowth') {
      const totalSchools = data.length;
      const percentage = (schoolsAboveThreshold / totalSchools) * 100;
      return schoolsAboveThreshold
    } else {
      return schoolsAboveThreshold
    }
  }

  /**
   * @author Manjunath
   * @uses to get data for Academic chart.
   */
  calculateDataForAcademic(data:any[], field: FieldName) {
    const thresholds = [
      { label: 'lessThan40', min: 0, max: 40 },
      { label: '41to50', min: 41, max: 50 },
      { label: '51to60', min: 51, max: 60 },
      { label: '61to74', min: 61, max: 74 },
      { label: '75to99', min: 75, max: 99 },
      { label: '100+', min: 100, max: Infinity },
    ];
    
    for (const threshold of thresholds) {
      this.academicChartData[threshold.label] = this.calculatePercentageOnThreshold(data, threshold.min, threshold.max, field);
    }
  }

  /**
   * @author Manjunath
   * @uses to get data for Catholic identity chart. 
   */
  calculateDataForCatholic(data:any[], field: FieldName) {
    const thresholds = [
      { label: 'lessThan40', min: 0, max: 40 },
      { label: '41to50', min: 41, max: 50 },
      { label: '51to60', min: 51, max: 60 },
      { label: '61to74', min: 61, max: 74 },
      { label: '75+', min: 75, max: Infinity },
    ];
    
    for (const threshold of thresholds) {
      this.catholicIdentityChartData[threshold.label] = this.calculatePercentageOnThreshold(data, threshold.min, threshold.max, field);
    }
  }

  /**
   * @author Manjunath
   * @uses to get data data for Enrollment chart 
   */
  getDataForEnrollmentChart(data:any[]) {
    const objectKeys = Object.keys(data[0]);
    let maxYear = -Infinity
    objectKeys.forEach(key => {
      const match = key.match(/FY(\d+)/);
      if (match) {
        const year = parseInt(match[1]);
        if (year > maxYear) {
          maxYear = year;
        }
      }
    })
    const regex = new RegExp(`FY${maxYear}`);
    const key = Object.keys(data[0]).find((key) => regex.test(key));
    const thresholds = [
      { label: '0To100', min: 0, max: 100 },
      { label: '101To150', min: 101, max: 150 },
      { label: '151To200', min: 151, max: 200 },
      { label: '200To250', min: 201, max: 250 },
      { label: '251To300', min: 251, max: 300 },
      { label: '301To350', min: 301, max: 350 },
      { label: '351+', min: 350, max: Infinity },
    ];

    for (const threshold of thresholds) {
      this.enrollmentChartData[threshold.label] = this.calculatePercentageOnThreshold(data, threshold.min, threshold.max, key);
    }
  }

  /**
   * @author Manjunath
   * @uses to get number of schools 
   */
  getNumberOfSchools(data:any[], type:string) {
    if (type === 'essential') {
      this.numberOfSchoolsOrganized = data.reduce((count, school) => {
        if (school.overAllPerformance == 'Organized' || school.overAllPerformance == 'Well-Organized') {
          count++;
        }
        return count;
      }, 0);
    } else {
      this.numberOfSchoolsBelowAvg = data.reduce((count, school) => {
        if (school['Avg. Enrollment in K-2'] < 20) {
          count++;
        }
        return count;
      }, 0);
    }
  }

  /**
   * @author Manjunath
   * @uses to re-draw the chart for academic performance.
   */
  handleAcademicChart(type: string) {
    if ( type === this.acadenicChartType) {
      return
    } else {
      if (type === 'reading') {
        this.acadenicChartType = 'reading'
        this.handleFilterOnGraph('academic')
      } else if (type === 'math') {
        this.acadenicChartType = 'math'
        this.handleFilterOnGraph('academic')
      } else {
        return
      }
    }
  }

  /**
   * @author Manjunath
   * @uses redirect to snapshot page.
   */
  handleTableOpen(type:string) {
    if(type === 'academic') {
      if(this.acadenicChartType === 'reading') {
        this._router.navigate(['/snapshot/'+'reading/'+this.selectedFilterForAcademic]);
      } else {
        this._router.navigate(['/snapshot/'+'math/'+this.selectedFilterForAcademic]);
      }
    } else if(type === 'essential') {
      this._router.navigate(['/snapshot/'+'essentials/'+this.selectedFilterForEssentials]);
    } else if(type === 'enrollment') {
      this._router.navigate(['/snapshot/'+'enrollment/'+this.selectedFilterForEnrollment]);
    } else if(type === 'catholic') {
      this._router.navigate(['/snapshot/'+'catholic/'+this.selectedFilterForCatholic]);
    }
  }

  /**
   * @author Manjunath
   * @uses to handle filters on Graphs.
   */
  handleFilterOnGraph(on: string) {
    const applyFilter = (data: any[], chartType: FieldName) => {
      if (this.selectedFilterForAcademic === 'all') {
        this.calculateDataForAcademic(data, chartType);
      } else if (this.selectedFilterForAcademic === 'BSF') {
        const BSF_SCHOOLS = data.filter((school: any) => school.BSF === 'Y');
        this.calculateDataForAcademic(BSF_SCHOOLS, chartType);
      } else {
        const REGIONED_SCHOOLS = data.filter((school: any) => school.region === this.selectedFilterForAcademic);
        this.calculateDataForAcademic(REGIONED_SCHOOLS, chartType);
      }
      this.acaChart.destroy();
      this.drawAcademicChart();
    };
  
    switch (on) {
      case 'academic':
        if (this.acadenicChartType === 'reading') {
          applyFilter(this.academicData, 'readingGrowth');
        } else {
          applyFilter(this.academicData, 'mathGrowth');
        }
        break;
  
      case 'essential':
        if (this.selectedFilterForEssentials === 'all') {
          this.getNumberOfSchools(this.essentialData, 'essential');
          this.getDataForEssential(this.essentialData);
        } else if (this.selectedFilterForEssentials === 'BSF') {
          const BSF_SCHOOLS = this.essentialData.filter((school: any) => school.BSF === 'Y');
          this.getNumberOfSchools(BSF_SCHOOLS, 'essential');
          this.getDataForEssential(BSF_SCHOOLS);
        } else {
          const REGIONED_SCHOOLS = this.essentialData.filter((school: any) => school.region === this.selectedFilterForEssentials);
          this.getNumberOfSchools(REGIONED_SCHOOLS, 'essential');
          this.getDataForEssential(REGIONED_SCHOOLS);
        }
        this.essentialChart.destroy();
        this.drawEssentialsChart();
        break;
  
      case 'catholic':
        if (this.selectedFilterForCatholic === 'all') {
          this.calculateDataForCatholic(this.catholicIdentityData, 'stronglyAgree');
        } else if (this.selectedFilterForCatholic === 'BSF') {
          const BSF_SCHOOLS = this.catholicIdentityData.filter((school: any) => school.BSF === 'Y');
          this.calculateDataForCatholic(BSF_SCHOOLS, 'stronglyAgree');
        } else {
          const REGIONED_SCHOOLS = this.catholicIdentityData.filter((school: any) => school.region === this.selectedFilterForCatholic);
          this.calculateDataForCatholic(REGIONED_SCHOOLS, 'stronglyAgree');
        }
        this.catholicIdentityChart.destroy();
        this.drawCatholicIdentityChart();
        break;
  
      case 'enrollment':
        if (this.selectedFilterForEnrollment === 'all') {
          this.getDataForEnrollmentChart(this.enrollmentSummaryData);
          this.getNumberOfSchools(this.enrollmentSummaryData, 'enrollment');
        } else if (this.selectedFilterForEnrollment === 'BSF') {
          const BSF_SCHOOLS = this.enrollmentSummaryData.filter((school: any) => school.BSF === 'Y');
          this.getDataForEnrollmentChart(BSF_SCHOOLS);
          this.getNumberOfSchools(BSF_SCHOOLS, 'enrollment');
        } else {
          const REGIONED_SCHOOLS = this.enrollmentSummaryData.filter((school: any) => school.region === this.selectedFilterForEnrollment);
          this.getDataForEnrollmentChart(REGIONED_SCHOOLS);
          this.getNumberOfSchools(REGIONED_SCHOOLS, 'enrollment');
        }
        this.enrollmentSummaryChart.destroy();
        this.drawEnrollmentSummaryChart();
        break;
  
      default:
        break;
    }
  }

  /**
   * @author Manjunath
   * @uses to to merge different arrays on schoolId
   */
  combineArrays(...arrays: any[][]): any[] {
    const combinedMap = new Map<number, any>();
    arrays.forEach(arr => {
      arr.forEach((obj: any) => {
        let schoolId = obj.schoolId ? obj.schoolId : obj.schoolID;
        if (schoolId == undefined) schoolId = obj.schoolID
        if (!combinedMap.has(schoolId)) {
          combinedMap.set(schoolId, { schoolId });
        }
        combinedMap.set(schoolId, { ...combinedMap.get(schoolId), ...obj });
      });
    });
    return Array.from(combinedMap.values());
  }

  /**
   * @author Manjunath
   * @uses to draw all charts on given data.
   */
  drawCharts(responseData: any) {
    // Get Filter Options
    const reg = responseData['Schools'].map((school: any) => {
      return school.region
    })
    const uniqueArray = Array.from(new Set(reg));
    this.filterOptions.push('BSF')
    this.filterOptions = [...this.filterOptions, ...uniqueArray.filter(item => item !== null).sort()];


    // Academic Performance
    this.academicData = this.combineArrays(responseData['Schools'], responseData['Academic Performance']);
    this.calculateDataForAcademic(this.academicData, 'readingGrowth');
    this.acaChart.destroy();
    this.drawAcademicChart();

    // 5 Essentials Performance Summary
    this.essentialData = this.combineArrays(responseData['Schools'], responseData['5 Essentials Performance Summary']);
    this.getNumberOfSchools(this.essentialData, 'essential');
    this.getDataForEssential(this.essentialData)
    this.essentialChart.destroy();
    this.drawEssentialsChart();

    // Catholic Identity Summary - I believe that God is present in my life
    this.catholicIdentityData = this.combineArrays(responseData['Schools'], responseData['Catholic Identity Summary - I believe that God is present in my life']);
    this.calculateDataForCatholic(this.catholicIdentityData, 'stronglyAgree')
    this.catholicIdentityChart.destroy();
    this.drawCatholicIdentityChart();

    // Enrollment Summary
    this.enrollmentSummaryData = this.combineArrays(responseData['Schools'], responseData['Enrollment Summary']);
    this.getDataForEnrollmentChart(this.enrollmentSummaryData)
    this.getNumberOfSchools(this.enrollmentSummaryData, 'enrollment');
    this.enrollmentSummaryChart.destroy();
    this.drawEnrollmentSummaryChart();
  }

  /**
   * @author Manjunath
   * @uses to get data all schools data for widgets.
   */
  getDataForWidgets() {
    const storedData = sessionStorage.getItem('AllSchoolsData');
    if (storedData) {
      setTimeout(() => this.drawCharts(JSON.parse(storedData)));
    } else {
      this._ajaxService.get('admin/getAllSchoolsData').then((response: any) => {
        if (response.statusCode == 200) {
          sessionStorage.setItem('AllSchoolsData', JSON.stringify(response.data));
          this.drawCharts(response.data)
        }
      });
    }
  }
}
