import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Page404Component } from './page404/page404.component';
import { SchoolComponent } from './school/school.component';
import { GoalComponent } from './goal/goal.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InterventionsComponent } from './interventions/interventions.component';
import { UsersComponent } from './users/users.component';
import { AcademicsComponent } from './academics/academics.component';
import { EssentialsComponent } from './essentials/essentials.component';
import { CatholicIdentityComponent } from './catholic-identity/catholic-identity.component';
import { EnrollmentComponent } from './enrollment/enrollment.component';
import { AdminComponent } from './admin/admin.component';
import { UploadDataComponent } from './upload-data/upload-data.component';
import { ActionPlanningSnapshotComponent } from './action-planning-snapshot/action-planning-snapshot.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    Page404Component,
    SchoolComponent,
    GoalComponent,
    InterventionsComponent,
    UsersComponent,
    AcademicsComponent,
    EssentialsComponent,
    CatholicIdentityComponent,
    EnrollmentComponent,
    AdminComponent,
    UploadDataComponent,
    ActionPlanningSnapshotComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSnackBarModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
