<section *ngIf="actionPlanningSnapShot">
  <div class="container-fluid bg-colorgrey">
    <div class="row mt-2 m-0">
      <div class="system-head pb-0 pt-2 mt-2 col-md-9">
        # of schools with an action plan for:
      </div>
      <div class="col-md-3 mt-2 p-0">
        <div class="wifd120">
          <label>Select Academic Year</label>
          <select class="form-select" [(ngModel)]="selectedYear" (change)="handleAcademicYearChange(selectedYear)">
            <option *ngFor="let option of acdemicYearList">{{ option }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 pl-0 pr-0 pt-2">
        <div class="d-tablees">
          <div class="table-responsive">
            <table class="table tables table-bordered">
              <thead>
                <tr>
                  <th>Academic</th>
                  <th>5 Essentials</th>
                  <th>Catholic Identity</th>
                  <th>Enrollment & Finance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="pinopen" ngbTooltip="Open" (click)="handleTableChange('academic')">
                      <a class="deleting tooltip-h">
                        <svg fill="#FFF" width="18px" height="18px" viewBox="0 0 32 32" id="icon">
                          <defs>
                            <style>
                              .cls-1 {
                                fill: none;
                              }
                            </style>
                          </defs>
                          <path
                            d="M28,10V28H10V10H28m0-2H10a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2Z"
                            transform="translate(0)" />
                          <path d="M4,18H2V4A2,2,0,0,1,4,2H18V4H4Z" transform="translate(0)" />
                          <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1"
                            width="32" height="32" />
                        </svg>
                      </a>
                    </div>
                    <div class="countoftd">{{ noOfSchoolsForAcademic }}</div>
                  </td>
                  <td>
                    <div class="pinopen" ngbTooltip="Open" (click)="handleTableChange('essential')">
                      <a class="deleting tooltip-h">
                        <svg fill="#FFF" width="18px" height="18px" viewBox="0 0 32 32" id="icon">
                          <defs>
                            <style>
                              .cls-1 {
                                fill: none;
                              }
                            </style>
                          </defs>
                          <path
                            d="M28,10V28H10V10H28m0-2H10a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2Z"
                            transform="translate(0)" />
                          <path d="M4,18H2V4A2,2,0,0,1,4,2H18V4H4Z" transform="translate(0)" />
                          <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1"
                            width="32" height="32" />
                        </svg>
                      </a>
                    </div>
                    <div class="countoftd">{{ noOfSchoolsForEssentials }}</div>
                  </td>
                  <td>
                    <div class="pinopen center" ngbTooltip="Open" (click)="handleTableChange('catholic')">
                      <a class="deleting tooltip-h">
                        <svg fill="#FFF" width="18px" height="18px" viewBox="0 0 32 32" id="icon">
                          <defs>
                            <style>
                              .cls-1 {
                                fill: none;
                              }
                            </style>
                          </defs>
                          <path
                            d="M28,10V28H10V10H28m0-2H10a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2Z"
                            transform="translate(0)" />
                          <path d="M4,18H2V4A2,2,0,0,1,4,2H18V4H4Z" transform="translate(0)" />
                          <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1"
                            width="32" height="32" />
                        </svg>
                      </a>
                    </div>
                    <div class="countoftd">{{ noOfSchoolsForCatholic }}</div>
                  </td>
                  <td>
                    <div class="pinopen" ngbTooltip="Open" (click)="handleTableChange('enrollment')">
                      <a class="deleting tooltip-h">
                        <svg fill="#FFF" width="18px" height="18px" viewBox="0 0 32 32" id="icon">
                          <defs>
                            <style>
                              .cls-1 {
                                fill: none;
                              }
                            </style>
                          </defs>
                          <path
                            d="M28,10V28H10V10H28m0-2H10a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2Z"
                            transform="translate(0)" />
                          <path d="M4,18H2V4A2,2,0,0,1,4,2H18V4H4Z" transform="translate(0)" />
                          <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1"
                            width="32" height="32" />
                        </svg>
                      </a>
                    </div>
                    <div class="countoftd">{{ noOfSchoolsForEnrollment }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-1">
      <div class="system-head pt-2 col-md-9">{{ tableHeading }}</div>
      <div *ngIf="enableTable && dataSource.data.length > 0" class="align-items-end pb-2 col-md-3">
        <input type="text" placeholder="Search by school name, submission date, etc...." class="form-input-my"
          (keyup)="applyFilter($event)" #input />
      </div>
    </div>
    <div class="bg-info text-white widthm-320" *ngIf="dataSource.data.length == 0">No Records Found</div>
    <div class="row mt-3 m-0" *ngIf="enableTable && dataSource.data.length > 0">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" Paginator matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>School Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="region">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Vicariate</th>
          <td mat-cell *matCellDef="let element">{{ element.region }}</td>
        </ng-container>

        <ng-container matColumnDef="BSF">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>BSF</th>
          <td mat-cell *matCellDef="let element">{{ element.BSF }}</td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Submission Date</th>
          <td mat-cell *matCellDef="let element">{{ element.createdOn | date: 'MM/dd/yyyy'}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="alert widtfixsize p-nomore show pl-1 pt-1 text-left fade">No records found!!</td>
        </tr>
      </table>
      <mat-paginator [pageSize]="15" [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons #Paginator></mat-paginator>
    </div>
  </div>
</section>



<section *ngIf="!actionPlanningSnapShot">
  <div class="container-fluid bg-colorgrey">
    <div class="bg-info text-white widthm-320" *ngIf="dataSource.data.length == 0">No Records Found</div>
    <div class="row mt-3 m-0" *ngIf="dataSource.data.length > 0">
      <div class="system-head pt-2 col-md-9">{{ tableHeading }}</div>
      <div class="pb-2 col-md-3">
        <input type="text" placeholder="Search by school name, etc...." class="form-input-my"
          (keyup)="applyFilter($event)" #input />
      </div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" Paginator matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>School Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="region">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Vicariate</th>
          <td mat-cell *matCellDef="let element">{{ element.region }}</td>
        </ng-container>

        <ng-container matColumnDef="BSF">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>BSF</th>
          <td mat-cell *matCellDef="let element">{{ element.BSF }}</td>
        </ng-container>

        <ng-container matColumnDef="extra">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{fourthColumnName}}
          </th>
          <td mat-cell *matCellDef="let element">{{getDataForFourthColumn(element)}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColForNonAP; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColForNonAP"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="alert widtfixsize p-nomore show pl-1 pt-1 text-left fade">No records found for "{{ input.value }}"
          </td>
        </tr>
      </table>
      <mat-paginator [pageSize]="15" [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons #Paginator></mat-paginator>
    </div>
  </div>
</section>