<!-- Header Part -->
<div class="conatainer-fluid">
    <div class="row m-0 header-block-main21"></div>
    <div class="row m-0 pt-3 bg-white pb-3">
        <div class="col-md-3">
            <div class="width-logo"> <img src="../../assets/images/logo.png" /> </div>
        </div>
        <div class="col-md-9" *ngIf="enableMenu">
            <div ngbDropdown>
                <div class="profile-user" id="dropdownBasic1" ngbDropdownToggle>{{username}}</div>
                <div class="profmenu" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="logout()">Logout</button>
                    <div class="respdis767">
                        <div class="menu-userresep" *ngIf="isInAdminPanel">
                            <ul>
                                <li routerLink="/dashboard"><a routerLinkActive="active">Dashboard</a></li>
                                <li routerLink="/admin"><a routerLinkActive="active">Archdiocese Data</a></li>
                                <li routerLink="/snapshot/actionPlanning/all"><a routerLinkActive="active">Action
                                        Planning</a>
                                </li>
                                <li routerLink="/uploadData" *ngIf="superAdminFlag"><a routerLinkActive="active">Upload
                                        CSIP
                                        data</a></li>
                            </ul>
                        </div>
                        <div class="menu-userresep" *ngIf="!isInAdminPanel">
                            <ul>
                                <li routerLink="/admin" *ngIf="adminFlag"><a routerLinkActive="active">Admin</a></li>
                                <li routerLink="/dashboard"><a routerLinkActive="active">Archdiocese Data</a></li>
                                <li routerLink="/planning"><a routerLinkActive="active">Action Planning</a></li>
                                <li routerLink="/users" *ngIf="enableUserMenu"><a routerLinkActive="active">Users</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <div class="respdis968">
                <div class="menu-user" *ngIf="isInAdminPanel">
                    <ul>
                        <li routerLink="/dashboard"><a routerLinkActive="active">Dashboard</a></li>
                        <li routerLink="/admin"><a routerLinkActive="active">Archdiocese Data</a></li>
                        <li routerLink="/snapshot/actionPlanning/all"><a routerLinkActive="active">Action Planning</a>
                        </li>
                        <li routerLink="/uploadData" *ngIf="superAdminFlag"><a routerLinkActive="active">Upload CSIP
                                data</a></li>
                    </ul>
                </div>
                <div class="menu-user" *ngIf="!isInAdminPanel">
                    <ul>
                        <li routerLink="/admin" *ngIf="adminFlag"><a routerLinkActive="active">Admin</a></li>
                        <li routerLink="/dashboard"><a routerLinkActive="active">Archdiocese Data</a></li>
                        <li routerLink="/planning"><a routerLinkActive="active">Action Planning</a></li>
                        <li routerLink="/users" *ngIf="enableUserMenu"><a routerLinkActive="active">Users</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Header Part End -->