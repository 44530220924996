import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import { AjaxService } from '../services/ajax.service';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

interface DataRow {
  [key: string]: any;
}

interface ParentColumn {
  name: string;
  childColumns: string[];
  data: DataRow[];
}
@Component({
  selector: 'app-upload-data',
  templateUrl: './upload-data.component.html',
  styleUrls: ['./upload-data.component.scss']
})
export class UploadDataComponent implements OnInit {

  uploadUserData : boolean = true;
  uploadCSIPData : boolean = false;
  uploadType: string = "usersData"
  tableData: ParentColumn[] = [];
  csipDataForTable: any = [];
  copyOfCsipDataForTable: any = [];
  excelfile: any;
  adminUserData: any[] = [];
  adminUserDataForTable: any[] = [];
  schoolUserData: any[] = [];
  schoolUserDataForTable: any[] = [];
  guestUserDataForTable: any[] = [];
  allSchoolsFromUserFile: any[] = [];
  csipData: any = [];
  enrollmentSummary: any = [];
  blueRibbonCalculator: any = [];
  csipDataSchools: any = [];
  userTableData: any[] = [];
  copyOfUserTableData: any[] = [];
  selectedValues: string[] = [];
  userTableColumnOrder: any[] = ['First Name', 'Last Name', 'School Id', 'Email', 'Alternate Email', 'Role Id'];
  linkColumns: any = ['BRCSpring', 'CI SCorecard', 'Data Meeting Guide'];
  columnsToCalculate: any = [];
  currentPage = 1;
  itemsPerPage = 10;
  searchText = ''
  constructor(private _ajaxService: AjaxService, private _userService: UserService, private _router: Router, private snackbar: MatSnackBar,) { }

  @ViewChild('input') input: ElementRef | undefined;
  @ViewChild('inputUserExcel') inputUserExcel: ElementRef | undefined;
  @ViewChild('inputCSIPExcel') inputCSIPExcel: ElementRef | undefined;

  ngOnInit(): void {
    this._userService.getUserDetails().subscribe((value: any) => {
      if (value != undefined && Object.keys(value).length > 0) {
        if (value.roleId != 5) {
          this._router.navigate(['/dashboard'])
        }
      }
    });
    this.getAllUsers();
    this.getSchoolData();
  }

  /**
   * @author Manjunath
   * @uses to handle option change for uploading excel file
   */
  changeDataUpload() {
    this.searchText = '';
    this.setPage(1);
    this.csipDataForTable = this.copyOfCsipDataForTable;
    this.filterForUserTable(null)
    if (this.uploadType === 'usersData') {
      this.uploadUserData = true;
      this.uploadCSIPData = false;
      this.resetCSIPdata()
      this.excelfile = null
    } else if (this.uploadType === 'csipData') {
      this.excelfile = null
      this.uploadUserData = false;
      this.uploadCSIPData = true;
    } else {
      this.uploadUserData = false;
      this.uploadCSIPData = false;
    }
  }

  /**
   * @author Manjunath
   * @uses to read excel file
   */
  onFileChange(event: any, fileType: any) {
    const file = event.target.files[0];
    this.excelfile = file
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      if (fileType === 'usersData') {
        const worksheetOne = workbook.Sheets[workbook.SheetNames[0]];
        const adminUserData = XLSX.utils.sheet_to_json(worksheetOne, { header: 1 });
        const worksheetTwo = workbook.Sheets[workbook.SheetNames[1]];
        const schoolLevelData = XLSX.utils.sheet_to_json(worksheetTwo, { header: 1 });
        this.adminUserData = this.updateUserData(adminUserData.slice(1), adminUserData[0] as string[], 'admin');
        this.schoolUserData = this.updateUserData(schoolLevelData.slice(1), schoolLevelData[0] as string[], 'principle');
        const adminUserSchools = this.getAllSchoolsFromUserFile(adminUserData.slice(1), adminUserData[0] as string[]);
        const userSchools = this.getAllSchoolsFromUserFile(schoolLevelData.slice(1), schoolLevelData[0] as string[]);
        this.allSchoolsFromUserFile = this.combineArrays(adminUserSchools, userSchools)
      }
      else {
        workbook.SheetNames.forEach((sheet) => {
          if (sheet !== '') {
            const worksheet: XLSX.WorkSheet = workbook.Sheets[sheet];
            const jsonData: any[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: true });
            const parentColumn = jsonData[0] as string[];
            const childColumn = jsonData[1] as string[];
            const dataRows: any[][] = jsonData.slice(2);
            const results = this.extractData(dataRows, parentColumn, childColumn)
            if (sheet === 'All Data') this.csipData = results
            if (this.csipData.length != 0) this.fomatCsipDataFile();
          }
        })
      }
    };
    fileReader.readAsArrayBuffer(file);
  }

  /**
   * @author Manjunath
   * @uses to extract data from excel sheets. 
   * @requires An excel sheet with 2 rows of header.
   */
  extractData(dataRows: any[], parentColumn: any[], childColumn: any[]) {
    const finalData: any[] = [];
    dataRows.forEach((school: any) => {
      let lastkey: string | null = null
      if (school.some((cell: string | null | undefined) => cell !== undefined && cell !== null && cell !== '')) {
        const response: { [key: string]: any } = {};
        for (let i = 0; i < school.length; i++) {
          if (parentColumn[i] == undefined) {
            const obj = {
              [childColumn[i]]: school[i]
            };
            // const lastItemKey = Object.keys(response).pop();
            if (lastkey !== null) {
              response[lastkey] = { ...response[lastkey], ...obj };
            } else {
              // response[childColumn[i]] = obj;
              response[childColumn[i]] = school[i];
            }
          } else {
            if (childColumn[i] !== undefined) {
              const obj = {
                [childColumn[i]]: school[i],
              };
              response[parentColumn[i]] = obj;
              lastkey = parentColumn[i]
            } else {
              response[parentColumn[i]] = school[i];
              lastkey = parentColumn[i]
            }

          }
        }
        if (response['School ID'] != undefined && response['School ID'] != null && response['School ID'] != '') finalData.push(response)
      }
      else {
        lastkey = null;
      }
    })
    return finalData
  }

  /**
   * @author Manjunath
   * @uses to format users data from excel file
   */
  updateUserData(userData:any[], userColumns:any[], type:string) {
    const fomattedData:any[] = [];
    if (userData.length > 0) {
      userData.forEach((user : any) => {
        let roleId : number = type == 'admin' ? 1 : 3;
        if (user.some((cell: string | null | undefined) => cell !== undefined && cell !== null && cell !== '')) {
          const rowData : { [key : string]: unknown} = {};
        for (let i=0; i < userColumns.length; i++) {
          const colName = userColumns[i];
          // const colVal = user[i];
          const colVal = colName != 'School ID' && user[i] != undefined ? user[i].trim() : user[i];
          rowData[colName] = colVal
        }
        if (rowData['Email'] =='halschwartz@archchicago.org' || rowData['Alternate Email (Microsoft)'] == 'halschwartz@archchicago.org' ) {
          roleId = 5
        }
        if (rowData['Email'] || rowData['Alternate Email (Microsoft)']) {
          rowData['roleId'] = roleId
          fomattedData.push(rowData)
        }
        }
      })
    }
    return fomattedData
  }

  /**
   * @author Manjunath
   * @uses to extract school details from user excel file
   */
  getAllSchoolsFromUserFile(data:any[], columnNames:any[]) {
    const fomattedData:any[] = [];
    const allSimplifiedSchools:any[] = [];
    if (data.length > 0) {
      data.forEach((school : any) => {
        if (school.some((cell: string | null | undefined) => cell !== undefined && cell !== null && cell !== '')) {
          const rowData : { [key : string]: unknown} = {};
        for (let i=0; i < columnNames.length; i++) {
          const colName = columnNames[i];
          const colVal = school[i];
          rowData[colName] = colVal
        }
        fomattedData.push(rowData)
        }
      })
      fomattedData.map(school => {
        allSimplifiedSchools.push ({
          'schoolId': school['School ID'],
          'schoolName': school['Schools'],
          region: school.Region,
          BSF: school.BSF === undefined ? 'N' : school.BSF
        });
      });
    }
    return allSimplifiedSchools
  }

  /**
   * @author Manjunath
   * @uses to update keys of user data while sending it to API
   * @returns an array with updated keys
   */
  formatKeysForUserData(data:any[]) {
    const fomattedData:any[] = [];
    data.map(user => {
      fomattedData.push ({
          'First Name': user['First Name'],
          'Last Name': user['Last Name'],
          'School ID': user['School ID'],
          'Schools': user['Schools'],
          'Email': user['Email'],
          'Alternate Email': user['Alternate Email (Microsoft)'],
          'roleId': user['roleId']
      });
    });
    return fomattedData
  }

  /**
   * @author Manjunath
   * @uses to to merge different arrays on schoolId
   */
  combineArrays(...arrays: any[][]): any[] {
    const combinedMap = new Map<number, any>();
    arrays.forEach(arr => {
      arr.forEach((obj: any) => {
        let schoolId = obj.schoolId;
        if (schoolId == undefined) schoolId = obj.schoolID
        if (!combinedMap.has(schoolId)) {
          combinedMap.set(schoolId, { schoolId });
        }
        combinedMap.set(schoolId, { ...combinedMap.get(schoolId), ...obj });
      });
    });
    return Array.from(combinedMap.values());
  }

  uploadExcelFile(uploadType: any) {
    if (!this.excelfile) {
      this.snackbar.open("Please upload the Excel template.", "OK", { duration: 3000, panelClass: "warning-dialog" });
      return;
    }
    else {
      if (uploadType === 'usersData') {
        const updatedUser = [...this.adminUserData, ...this.schoolUserData]
        const fomattedUser = this.formatKeysForUserData(updatedUser)
        let request = {
          'userId' : 1,
          "users": fomattedUser,
          "schools": this.allSchoolsFromUserFile,
        }
        this._ajaxService.post('admin/saveExcelData', request).then((response: any) => {
          if (response.statusCode == 200) {
            sessionStorage.removeItem('AllSchoolsData');
            if (this.inputUserExcel) this.inputUserExcel.nativeElement.value = '';
            this.snackbar.open("Excel file uploaded successfully", "OK", { duration: 3000, panelClass: "success-dialog" });
            this.getAllUsers();
          }
          else if (response.statusCode == 401) {
            this.snackbar.open("Schools data can't be empty", "OK", { duration: 3000, panelClass: "warning-dialog" })
          } else {
            this.snackbar.open(`Error while uploding : ${response.error.message}`, "OK", { duration: 3000, panelClass: "warning-dialog" })
          }
        });
      }
      else {
        let request = {
          'schools': this.csipDataSchools,
          'enrollmentSummary': this.enrollmentSummary,
          'blueRibbonCalculator': this.blueRibbonCalculator,
        }
        this._ajaxService.post('admin/saveCSIPData', request).then((response: any) => {
          if (response.statusCode == 200) {
            sessionStorage.removeItem('AllSchoolsData');
            if (this.inputCSIPExcel) this.inputCSIPExcel.nativeElement.value = '';
            this.snackbar.open("Data uploaded successfully", "OK", { duration: 3000, panelClass: "success-dialog" });
            this.getSchoolData();
          }
          else {
            this.snackbar.open(`Error while uploding : ${response.error.message}`, "OK", { duration: 3000, panelClass: "warning-dialog" })
          }
        });
      }
    }
  }

  /**
  * @author Manjunath
  * @uses to Reset CSIP data arrays
  */
  resetCSIPdata() {
    this.csipData = [];
    this.enrollmentSummary = [];
    this.blueRibbonCalculator = [];
    this.csipDataSchools = [];
  }

  /**
   * @author Manjunath
   * @uses to format CSIP data file
   */
  fomatCsipDataFile() {
    this.csipData.forEach((school : any) => {
      const schools = {
        'schoolName': school['Schools'],
        'schoolId': school['School ID'],
        'readingGrowth' : !isNaN(Number(school['Academic Performance']['Reading Growth'])) ? (Number(school['Academic Performance']['Reading Growth']) * 100).toFixed(2) : 0,
        'mathGrowth' : !isNaN(Number(school['Academic Performance']['Math Growth'])) ? (Number(school['Academic Performance']['Math Growth']) * 100).toFixed(2) : 0,
        'stronglyAgree': !isNaN(Number(school['Catholic Identity Summary - I believe that God is present in my life.']['Strongly Agree'])) ? (Number(school['Catholic Identity Summary - I believe that God is present in my life.']['Strongly Agree']) * 100).toFixed(2) : 0,
        'agree': !isNaN(Number(school['Catholic Identity Summary - I believe that God is present in my life.']['Agree'])) ? (Number(school['Catholic Identity Summary - I believe that God is present in my life.']['Agree']) * 100).toFixed(2) : 0,
        'disagree' : !isNaN(Number(school['Catholic Identity Summary - I believe that God is present in my life.']['Disagree'])) ? (Number(school['Catholic Identity Summary - I believe that God is present in my life.']['Disagree']) * 100).toFixed(2) : 0,
        'stronglyDisagree' : !isNaN(Number(school['Catholic Identity Summary - I believe that God is present in my life.']['Strongly Disagree'])) ? (Number(school['Catholic Identity Summary - I believe that God is present in my life.']['Strongly Disagree']) * 100).toFixed(2) : 0,
        'readingGrowthBelow75' : school['i-Ready Growth']['I-Ready Reading % Meeting Typical Annual Growth Below 75%'],
        'mathGrowthBelow75' : school['i-Ready Growth']['I-Ready Math % Meeting Typical Annual Growth Below 75%'],
        'lessThan75StronglyAgree' : school['Catholic Identity']['< 75% of students “Strongly Agree” that “God is present in their life'],
        'enrollmentDrop' : school['Enrollment & Operations Flags']['Enrollment Drop from FY22-23 >5%'],
        'enrollmentLessThan240' : school['Enrollment & Operations Flags']['FY22 Enrollment <240'],
        'avgEnrollmentLessThan20' : school['Enrollment & Operations Flags']['FY23 Avg K-2 Enrollment <20'],
        "overallPerformance": school['5Essentials Performance Summary']['Overall Performance'],
        "effectiveLeaders": school['5Essentials Performance Summary']['Effective Leaders'],
        "collaborativeTeachers": school['5Essentials Performance Summary']['Collaborative Teachers'],
        "involvedFamilies": school['5Essentials Performance Summary']['Involved Families'],
        "supportiveEnvironment": school['5Essentials Performance Summary']['Supportive Environment'],
        "ambitiousInstruction": school['5Essentials Performance Summary']['Ambitious Instruction'],
        "overallRating": school['5Essentials Flags']['5-Essentials Overall Rating = Weak or Very Weak'],
        "lowResponse": school['5Essentials Flags']['Low Response Rate Concern'],
        "categorizedAsStrong": school['5Essentials Flags']['<  3 out of 5 Essentials categorized as Strong'],
        "CIScorecard": school['CI Scorecards'],
        "totalFlags": school['Blue Ribbon Calculator']['Total Flags'],
        "dataMeetingGuide": school['Data Meeting Guide'],
      }
      this.csipDataSchools.push(schools)
    })
    this.getEnrollmentSummary();
    this.getBlueRibbonCal();
  }

  /**
  * @author Manjunath
  * @uses to get enrollment summary from uploaded csip data file
  */
  getEnrollmentSummary() {
    const regex = /FY\d{4}/;
    if (this.csipData.length !== 0) {
      this.enrollmentSummary = this.csipData.reduce((acc: any[], obj: any) => {
        const matchingKeys = Object.keys(obj['Enrollment Summary']).filter((key) => regex.test(key));
        if (matchingKeys.length > 0) {
          const filteredObj: any = {};
          matchingKeys.forEach((key) => {
            const updatedKey = key.split('Y');
            const year = parseInt(updatedKey[1], 10);
            const value = obj['Enrollment Summary'][key];
            filteredObj['year'] = year;
            filteredObj['value'] = value == 'N/A' || value == 'n/a' || value == null ? null : value;
            if (obj.hasOwnProperty('School ID')) {
              filteredObj['schoolId'] = obj['School ID'];
            }
            if (obj['Enrollment Summary'].hasOwnProperty('Avg. Enrollment in K-2')) {
              filteredObj.avgEnrollment = obj['Enrollment Summary']['Avg. Enrollment in K-2'] == 'N/A' || obj['Enrollment Summary']['Avg. Enrollment in K-2'] == 'n/a' || obj['Enrollment Summary']['Avg. Enrollment in K-2'] == null ? null : obj['Enrollment Summary']['Avg. Enrollment in K-2'];
            }
            acc.push({...filteredObj});
          });
        }
        return acc;
      }, []);
    }
  }

  /**
  * @author Manjunath
  * @uses to get blue ribbon calculator data from uploaded csip data file
  */
 getBlueRibbonCal() {
  const regex = /BRC (Spring|Fall) \d{4}/;
  if (this.csipData.length != 0) {
    this.blueRibbonCalculator = this.csipData.reduce((acc: { [key: string]: string }[], obj: any) => {
      const matchingKeys = Object.keys(obj['Blue Ribbon Calculator']).filter(key => regex.test(key));
      if (matchingKeys.length > 0) {
        const filteredObj = matchingKeys.reduce((filtered, key) => {
          try {
            const updatedKey = key.split(' ')
            filtered[updatedKey[1]] = obj['Blue Ribbon Calculator'][key] as string;
            filtered['year'] = updatedKey[2]
          } catch {
            filtered[key] = obj['Blue Ribbon Calculator'][key] as string;
            filtered['year'] = key 
          }
          return filtered;
        }, {} as { [key: string]: string });
        if (obj.hasOwnProperty("School ID")) {
          filteredObj["schoolId"] = obj["School ID"] as string;
        }
        acc.push(filteredObj);
      }
      return acc;
    }, []);
  }
 }

  /**
  * @author Manjunath
  * @uses to update key Values for User Table
  */
  updateKeysOfUserTable(userArray: any[]): any[] {
    const keyConfig: { [key: string]: string } = {
      'schoolId': 'School Id',
      'email' : 'Email',
      'firstName' : 'First Name',
      'lastName' : 'Last Name',
      'alternateEmail' : 'Alternate Email',
      'roleId' : 'Role Id',
    }
    if (userArray.length > 0) {
      return userArray.map(user => {
        const updatedSchool: any = {};
  
        Object.keys(user).forEach(oldKey => {
          const newKey = keyConfig[oldKey];
          updatedSchool[newKey || oldKey] = user[oldKey];
        });
        if (updatedSchool['Role Id'] == 5) updatedSchool['Role Id'] = 'Super Admin'
        if (updatedSchool['Role Id'] == 1) updatedSchool['Role Id'] = 'Admin'
        if (updatedSchool['Role Id'] == 3) updatedSchool['Role Id'] = 'Principal'
        if (updatedSchool['Role Id'] == 4) updatedSchool['Role Id'] = 'Guest'
        return updatedSchool;
      });
    } else {
      return []
    }

  }

  /**
  * @author Manjunath
  * @uses to get All Users
  */
  getAllUsers() {
    let request = {}
    this._ajaxService.post('admin/getHierarchyDataForAdmin', request).then((response: any) => {
      if (response.statusCode == 200) {
        this.adminUserDataForTable = this.updateKeysOfUserTable(response.data['superAdmins']);
        this.schoolUserDataForTable = this.updateKeysOfUserTable(response.data['schoolLevelUsers'])
        this.guestUserDataForTable = this.updateKeysOfUserTable(response.data['guestUsers'])
        this.userTableData = [...this.adminUserDataForTable, ...this.schoolUserDataForTable, ...this.guestUserDataForTable];
        this.copyOfUserTableData = [...this.adminUserDataForTable, ...this.schoolUserDataForTable, ...this.guestUserDataForTable];
        this.getPaginatedData('user');
      }
    });
  }
  
  /**
   * @author Manjunath
   * @uses to filter the user table
   * @param event 
   */
  filterForUserTable(event: any | null) {
    if (event != null) {
      const value = event.target.value;
      if (event.target.checked) {
        this.selectedValues.push(value);
      } else {
        const index = this.selectedValues.indexOf(value);
        if (index !== -1) {
          this.selectedValues.splice(index, 1);
        }
      }
    } else {
      this.selectedValues = [];
    }
    const fil: { [key: string]: any[] } = {
      'Admin': this.adminUserDataForTable,
      'Principal': this.schoolUserDataForTable,
      'Guest': this.guestUserDataForTable,
    };
    let count = 0;
    for(const value in fil){
      if (this.selectedValues.length > 0){
        if(this.selectedValues.includes(value)) {
          count == 0 ? this.userTableData = [...fil[value]] : this.userTableData = [...this.userTableData, ...fil[value]]
          count == 0 ? this.copyOfUserTableData = [...fil[value]] : this.copyOfUserTableData = [...this.copyOfUserTableData, ...fil[value]]
          count++
          if (this.searchText != '' && this.searchText) {
            this.applyFilter(null, 'user');
          }
        }
      } else {
        this.userTableData = [...this.adminUserDataForTable, ...this.schoolUserDataForTable, ...this.guestUserDataForTable]
        this.copyOfUserTableData = [...this.adminUserDataForTable, ...this.schoolUserDataForTable, ...this.guestUserDataForTable];
        if (this.searchText != '' && this.searchText) {
          this.applyFilter(null, 'user');
        }
      }
      
    }
  }

  customSort(item:any) {
    return item.startsWith('FY') ? 0 : 1;
  }

  /**
  * @author Manjunath
  * @uses to transform CSIP data for table
  */
  transformData(data: any): ParentColumn[] {
    const transformedData: ParentColumn[] = [];
  
    for (const parentColumnName in data) {
      if (data.hasOwnProperty(parentColumnName)) {
        let filteredChildColumns:any[] = [];
        let filteredData:any[] = [];
        try {
          const childColumns = Object.keys(data[parentColumnName][0]);
          if (childColumns.length > 0) {
            if (parentColumnName === 'School ID') {
              filteredChildColumns = ['schoolId', ...childColumns.filter(col => col !== 'schoolId')];
            } else if (parentColumnName === 'Enrollment Summary') {
              const nonSchoolIdColumns = childColumns.filter(col => col !== 'schoolId');
              const sortedColumns = nonSchoolIdColumns.sort((a, b) => this.customSort(a) - this.customSort(b) || a.localeCompare(b));
              filteredChildColumns = sortedColumns;
            } else {
              filteredChildColumns = childColumns.filter(col => (col !== 'schoolId' && col !== 'schoolID' && col !== 'BSF' && col !== 'region'));
            }
      
            filteredData = data[parentColumnName].map((row: DataRow) => {
              const filteredRow: DataRow = {};
              for (const col of filteredChildColumns) {
                filteredRow[col] = row[col];
              }
              return filteredRow;
            });
  
            const parentColumn: ParentColumn = {
              name: parentColumnName,
              childColumns: filteredChildColumns,
              data: filteredData
            };
            transformedData.push(parentColumn);
          }
        } catch {
          let dummyText = `Data Not Found For ${parentColumnName}` 
          filteredChildColumns = [dummyText]
        }
      }
    }
    return transformedData;
  }

  /**
  * @author Manjunath
  * @uses to update key Values for CSIP Data Table
  */
  updateKeys(schoolsArray: any[]): any[] {
    const keyConfig: { [key: string]: string } = {
      'schoolId': 'School Id',
      'name': 'School Name',
      'readingGrowth': 'Reading Growth',
      'mathGrowth': 'Math Growth',
      'effectiveLeaders': 'Effective Leaders',
      'overAllPerformance': 'Overall Performance',
      'collaborativeTeachers': 'Collaborative Teachers',
      'involvedFamilies': 'Involved Families',
      'supportiveEnvironment': 'Supportive Environment',
      'ambitiousInstruction': 'Ambitious Instruction',
      'overallRating':'5-Essentials Overall Rating = Weak or Very Weak',
      'lowResponse':'Low Response Rate Concern',
      'categorizedAsStrong':'<  3 out of 5 Essentials categorized as Strong',
      'enrollmentLessThan240':'Enrollment is <240',
      'enrollmentDrop':'Enrollment Drop from FY22-23 >5%',
      'avgEnrollmentLessThan20':'Avg. enrollment is <20',
      'stronglyAgree':'Strongly Agree',
      'agree':'Agree',
      'disagree':'Disagree',
      'stronglyDisagree':'Strongly Disagree',
      'scoreCardLink':'CI SCorecard',
      'lessThan75StronglyAgree' : '< 75% of students “Strongly Agree” that “God is present in their life',
      'dataMeetingGuideLink' : 'Data Meeting Guide',
      'iReadyReadingPercent':'I-Ready Reading % Meeting Typical Annual Growth Below 75%',
      'iReadyMathPercent':'I-Ready Math % Meeting Typical Annual Growth Below 75%',
    };
  
    this.tableData.forEach(parentColumn => {
      parentColumn.childColumns = parentColumn.childColumns.map(oldColumn => keyConfig[oldColumn] || oldColumn);
    });
  
    return schoolsArray.map(school => {
      const updatedSchool: any = {};
  
      Object.keys(school).forEach(oldKey => {
        const newKey = keyConfig[oldKey];
        updatedSchool[newKey || oldKey] = school[oldKey];
      });
  
      return updatedSchool;
    });
  }

  /**
   * @author Manjunath
   * @uses to filter tables on search value.
   */
  applyFilter(event: Event | null, applyFilterOn : string) {
    let filterValue = ''
    if (event === null || event === undefined) {
      filterValue = this.searchText.trim().toLowerCase();
    } else {
      filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    }
    this.searchText = filterValue
    if (filterValue != null && filterValue != '' && filterValue != undefined) {
      if (applyFilterOn === 'csip') {
        this.csipDataForTable = this.copyOfCsipDataForTable;
        const filteredData:any[] = [];
        this.csipDataForTable.filter((school: any) => {
          if (school['School Name'].trim().toLowerCase().includes(filterValue)) {
            filteredData.push(school);
          }
        })
        this.csipDataForTable = filteredData
      } else {
        this.userTableData = this.copyOfUserTableData;
        const filteredData:any[] = [];
        this.userTableData.filter((user: any) => {
          if (user['First Name'].trim().toLowerCase().includes(filterValue) || user['Last Name'].trim().toLowerCase().includes(filterValue) || ( user['Email'] && user['Email'].trim().toLowerCase().includes(filterValue)) || (user['Alternate Email'] && user['Alternate Email'].trim().toLowerCase().includes(filterValue))) {
            filteredData.push(user);
          }
        })
        this.userTableData = filteredData
      }
    } else {
      this.csipDataForTable = this.copyOfCsipDataForTable;
      this.userTableData = this.copyOfUserTableData;
    }
  }

  /**
  * @author Manjunath
  * @uses to get numbers of pages
  */
  getPageNumbers(name: string): number[] {
    if (name === 'csip') {
      const totalPages = Math.ceil(this.csipDataForTable.length / this.itemsPerPage);
      return Array(totalPages).fill(0).map((_, index) => index + 1);
    } else {
      const totalPages = Math.ceil(this.userTableData.length / this.itemsPerPage);
      return Array(totalPages).fill(0).map((_, index) => index + 1);
    }
  }

  setPage(pageNumber: number) {
    this.currentPage = pageNumber;
  }

  /**
  * @author Manjunath
  * @uses to get paginated data
  */
  getPaginatedData(name: string): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    if (name === 'csip') {
      return this.csipDataForTable.slice(startIndex, endIndex);
    } else {
      return this.userTableData.slice(startIndex, endIndex);
    }
  }

  /**
  * @author Manjunath
  * @uses to update CSIP table Values for links and calculations
  */
  updateCellValue(columnName: string, cellData: any): any {
    if (this.linkColumns.includes(columnName)) {
      if (cellData !== undefined && cellData != null && cellData != '') {
        return `<a href="${cellData}" target="_blank">link</a>`;
      } else {
        return cellData;
      };
    } else if (this.columnsToCalculate.includes(columnName)) {
      if (cellData !== undefined && cellData != null && cellData != '' && cellData != 'N/A') {
        return (Number(cellData)).toFixed(2);
      } else {
        return 0;
      };
    };
    return cellData;
  }

  /**
  * @author Manjunath
  * @uses to get css class based on table cell value
  */
  getCellClass(cellData: any): any {
    if (cellData === 'Organized') {
      return 'organized';
    } else if (cellData === 'Well-Organized') {
      return 'well-organized';
    } else if (cellData === 'Partially Organized') {
      return 'partially'
    } else if (cellData === 'Moderately Organized') {
      return 'moderate'
    } else if (cellData === 'Not Yet Organized') {
      return 'notyet'
    }
    return null;
   }

  /**
  * @author Manjunath
  * @uses to get all school details
  */
  getSchoolData() {
    this._ajaxService.get('admin/getAllSchoolsData').then((response: any) => {
      if (response.statusCode == 200) {
        if (response.data['Schools'].length > 0) {
          sessionStorage.setItem('AllSchoolsData', JSON.stringify(response.data));
          this.tableData = this.transformData(response.data);
          const combinedArray: any[] = this.combineArrays(response.data['Schools'], 
                                                          response.data['School ID'],
                                                          response.data['Academic Performance'], 
                                                          response.data['5 Essentials Performance Summary'],
                                                          response.data['Catholic Identity Summary - I believe that God is present in my life'],
                                                          response.data['Enrollment Summary'],
                                                          response.data['i-Ready Growth'],
                                                          response.data['5 Essentials Flags'],
                                                          response.data['Catholic Identity'],
                                                          response.data['Enrollment & Operations Flags'],
                                                          response.data['CI Scorecards'],
                                                          response.data['Data Meeting Guide'],
                                                          response.data['Blue Ribbon Calculator'],
                                                          );
          this.csipDataForTable = this.updateKeys(combinedArray);
          this.copyOfCsipDataForTable = this.updateKeys(combinedArray);
        }
      }
    })
   }

}
