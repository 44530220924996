<section>
    <div class="container-fluid bg-colorgrey">
        <div class="row mt-3 m-0">
            <div class="col-md-6">
                <div class="card min-height-291  mt-2 mb-2">
                    <div class="card-body position-relative">
                        <div class="d-flex mt-2">
                            <div class="card-title font-weight-600">
                                <a href="#" target="_blank">Academic
                                    Performance Summary</a>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md-8 mt-2 pl-0">
                                <span class="b-rendd">
                                    <span class="b-rend" [ngClass]="acadenicChartType == 'reading' ? 'active' : ''" (click)="handleAcademicChart('reading')"><a>Reading</a></span>
                                    <span class="b-rend" [ngClass]="acadenicChartType == 'math' ? 'active' : ''" (click)="handleAcademicChart('math')"><a>Math</a></span>
                                </span>
                            </div>
                            <div class="col-md-1 p-0 text-right" (click)="handleTableOpen('academic')">
                                <span class="pinopen position-relative" ngbTooltip="Open"> <a
                                        class="deleting mt-2 tooltip-h">
                                        <svg fill="#FFF" width="18px" height="18px" viewBox="0 0 32 32" id="icon">
                                            <defs>
                                                <style>
                                                    .cls-1 {
                                                        fill: none;
                                                    }
                                                </style>
                                            </defs>
                                            <path
                                                d="M28,10V28H10V10H28m0-2H10a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2Z"
                                                transform="translate(0)" />
                                            <path d="M4,18H2V4A2,2,0,0,1,4,2H18V4H4Z" transform="translate(0)" />
                                            <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;"
                                                class="cls-1" width="32" height="32" />
                                        </svg>
                                    </a></span>
                            </div>
                            <div class="col-md-3 p-0 text-right">
                                <select class="form-select" [(ngModel)]="selectedFilterForAcademic" (change)="handleFilterOnGraph('academic')">
                                    <option value="all">All Schools</option>
                                    <option *ngFor="let option of filterOptions" [value]="option">{{option}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="content-page">
                            <!-- <div class="sub-title mb-5">% of Schools Meeting Annual Typical Growth</div> -->
                            <canvas width="400" height="230" #academicChart></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card min-height-291  mt-2 mb-2">
                    <div class="card-body position-relative">
                        <div class="d-flex mt-2">
                            <div class="card-title font-weight-600">
                                <a href="#" target="_blank">5 Essentials Summary</a>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md-3 offset-md-9 p-0 text-right">
                                <select class="form-select" [(ngModel)]="selectedFilterForEssentials" (change)="handleFilterOnGraph('essential')">
                                    <option value="all">All Schools</option>
                                    <option *ngFor="let option of filterOptions" [value]="option">{{option}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="content-page">

                            <canvas width="400" height="200" #essentialsChart></canvas>
                            <div class="sub-title mb-0 mt-2 text-end">
                                <span class="center pb-2"> # of Schools at "Organized" and "Well Organized" Overall:</span>
                                <span class="vakuenum center">{{numberOfSchoolsOrganized}}</span>
                                <span (click)="handleTableOpen('essential')" class="pinopen posrmenrel center" ngbTooltip="Open"> <a class="deleting tooltip-h">
                                        <svg fill="#FFF" width="18px" height="18px" viewBox="0 0 32 32" id="icon">
                                            <defs>
                                                <style>
                                                    .cls-1 {
                                                        fill: none;
                                                    }
                                                </style>
                                            </defs>
                                            <path
                                                d="M28,10V28H10V10H28m0-2H10a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2Z"
                                                transform="translate(0)" />
                                            <path d="M4,18H2V4A2,2,0,0,1,4,2H18V4H4Z" transform="translate(0)" />
                                            <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;"
                                                class="cls-1" width="32" height="32" />
                                        </svg>
                                    </a></span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card min-height-291  mt-2 mb-2">
                    <div class="card-body position-relative">
                        <div class="d-flex mt-2">
                            <div class="card-title font-weight-600">
                                <a href="#" target="_blank">Catholic Identity Summary</a>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md-8">
                                <div class="sub-title pt-2 mb-0"># of Schools at each percentage</div>
                            </div>
                            <div class="col-md-1 p-0 text-right">
                                <span (click)="handleTableOpen('catholic')" class="pinopen position-relative" ngbTooltip="Open"> <a
                                        class="deleting mt-2 tooltip-h">
                                        <svg fill="#FFF" width="18px" height="18px" viewBox="0 0 32 32" id="icon">
                                            <defs>
                                                <style>
                                                    .cls-1 {
                                                        fill: none;
                                                    }
                                                </style>
                                            </defs>
                                            <path
                                                d="M28,10V28H10V10H28m0-2H10a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2Z"
                                                transform="translate(0)" />
                                            <path d="M4,18H2V4A2,2,0,0,1,4,2H18V4H4Z" transform="translate(0)" />
                                            <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;"
                                                class="cls-1" width="32" height="32" />
                                        </svg>
                                    </a></span>
                            </div>
                            <div class="col-md-3 p-0 text-right">
                                <select class="form-select" [(ngModel)]="selectedFilterForCatholic" (change)="handleFilterOnGraph('catholic')">
                                    <option value="all">All Schools</option>
                                    <option *ngFor="let option of filterOptions" [value]="option">{{option}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="content-page">
                            <!-- <div class="sub-title mb-5">% of Schools Meeting Annual Typical Growth</div> -->
                            <canvas width="400" height="220" #catholicChart></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card min-height-291  mt-2 mb-2">
                    <div class="card-body position-relative">
                        <div class="d-flex mt-2">
                            <div class="card-title font-weight-600">
                                <a href="#" target="_blank">Enrollment Summary</a>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md-3 offset-md-9 p-0 text-right">
                                <select class="form-select" [(ngModel)]="selectedFilterForEnrollment" (change)="handleFilterOnGraph('enrollment')">
                                    <option value="all">All Schools</option>
                                    <option *ngFor="let option of filterOptions" [value]="option">{{option}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="content-page">

                            <canvas width="400" height="200" #enrollmentChart></canvas>
                            <div class="sub-title mb-0 mt-2 text-end"> 
                                <span class="center pb-2"> # of Schools with avg. K-2 enrollment < 20:</span>
                                <span class="vakuenum center">{{numberOfSchoolsBelowAvg}}</span>
                                    <span (click)="handleTableOpen('enrollment')" class="pinopen posrmenrel center" ngbTooltip="Open"> <a class="deleting tooltip-h">
                                            <svg fill="#FFF" width="18px" height="18px" viewBox="0 0 32 32" id="icon">
                                                <defs>
                                                    <style>
                                                        .cls-1 {
                                                            fill: none;
                                                        }
                                                    </style>
                                                </defs>
                                                <path
                                                    d="M28,10V28H10V10H28m0-2H10a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2Z"
                                                    transform="translate(0)" />
                                                <path d="M4,18H2V4A2,2,0,0,1,4,2H18V4H4Z" transform="translate(0)" />
                                                <rect id="_Transparent_Rectangle_"
                                                    data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32"
                                                    height="32" />
                                            </svg>
                                        </a></span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- <div class="row mt-3 m-0">
            <div class="system-head col-md-12"># of schools with an action plan for:</div>
            <div class="col-md-12">
                <div class="d-tablees">
                    <div class="table-responsive">
                        <table class="table tables table-bordered">
                            <thead>
                                <tr>
                                    <th>Academic</th>
                                    <th>5 Essentials</th>
                                    <th>Catholic Identity</th>
                                    <th>Enrollment & Finance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="pinopen" ngbTooltip="Open"> <a class="deleting tooltip-h">
                                                <svg fill="#FFF" width="18px" height="18px" viewBox="0 0 32 32"
                                                    id="icon">
                                                    <defs>
                                                        <style>
                                                            .cls-1 {
                                                                fill: none;
                                                            }
                                                        </style>
                                                    </defs>
                                                    <path
                                                        d="M28,10V28H10V10H28m0-2H10a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2Z"
                                                        transform="translate(0)" />
                                                    <path d="M4,18H2V4A2,2,0,0,1,4,2H18V4H4Z"
                                                        transform="translate(0)" />
                                                    <rect id="_Transparent_Rectangle_"
                                                        data-name="&lt;Transparent Rectangle&gt;" class="cls-1"
                                                        width="32" height="32" />
                                                </svg>
                                            </a></div>
                                        <div class="countoftd">52</div>
                                    </td>
                                    <td>
                                        <div class="pinopen" ngbTooltip="Open"> <a class="deleting tooltip-h">
                                                <svg fill="#FFF" width="18px" height="18px" viewBox="0 0 32 32"
                                                    id="icon">
                                                    <defs>
                                                        <style>
                                                            .cls-1 {
                                                                fill: none;
                                                            }
                                                        </style>
                                                    </defs>
                                                    <path
                                                        d="M28,10V28H10V10H28m0-2H10a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2Z"
                                                        transform="translate(0)" />
                                                    <path d="M4,18H2V4A2,2,0,0,1,4,2H18V4H4Z"
                                                        transform="translate(0)" />
                                                    <rect id="_Transparent_Rectangle_"
                                                        data-name="&lt;Transparent Rectangle&gt;" class="cls-1"
                                                        width="32" height="32" />
                                                </svg>
                                            </a></div>
                                        <div class="countoftd">124</div>
                                    </td>
                                    <td>
                                        <div class="pinopen" ngbTooltip="Open"> <a class="deleting tooltip-h">
                                                <svg fill="#FFF" width="18px" height="18px" viewBox="0 0 32 32"
                                                    id="icon">
                                                    <defs>
                                                        <style>
                                                            .cls-1 {
                                                                fill: none;
                                                            }
                                                        </style>
                                                    </defs>
                                                    <path
                                                        d="M28,10V28H10V10H28m0-2H10a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2Z"
                                                        transform="translate(0)" />
                                                    <path d="M4,18H2V4A2,2,0,0,1,4,2H18V4H4Z"
                                                        transform="translate(0)" />
                                                    <rect id="_Transparent_Rectangle_"
                                                        data-name="&lt;Transparent Rectangle&gt;" class="cls-1"
                                                        width="32" height="32" />
                                                </svg>
                                            </a></div>
                                        <div class="countoftd">23</div>
                                    </td>
                                    <td>
                                        <div class="pinopen" ngbTooltip="Open"> <a class="deleting tooltip-h">
                                                <svg fill="#FFF" width="18px" height="18px" viewBox="0 0 32 32"
                                                    id="icon">
                                                    <defs>
                                                        <style>
                                                            .cls-1 {
                                                                fill: none;
                                                            }
                                                        </style>
                                                    </defs>
                                                    <path
                                                        d="M28,10V28H10V10H28m0-2H10a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2Z"
                                                        transform="translate(0)" />
                                                    <path d="M4,18H2V4A2,2,0,0,1,4,2H18V4H4Z"
                                                        transform="translate(0)" />
                                                    <rect id="_Transparent_Rectangle_"
                                                        data-name="&lt;Transparent Rectangle&gt;" class="cls-1"
                                                        width="32" height="32" />
                                                </svg>
                                            </a></div>
                                        <div class="countoftd">147</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->


        <!-- <div class="row mt-3 m-0">
            <div class="system-head pt-2 col-md-6">Academic Action Plans Submitted</div>
            <div class="pb-0 col-md-5 offset-md-1">
                <input type="text" placeholder="(Search by school name, submission date, etc.)" class="form-input-my">
            </div>
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table tables text-center bg-white table-bordered">
                        <thead>
                            <tr>
                                <th class="text-left">School Name</th>
                                <th>Vicariate</th>
                                <th>BSF</th>
                                <th>Submission Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-left">St. Alphonsus Liguori School
                                </td>
                                <td>I</td>
                                <td>No</td>
                                <td>10/22/22</td>
                            </tr>
                            <tr>
                                <td class="text-left">St. Anastasia School
                                </td>
                                <td>I</td>
                                <td>No</td>
                                <td>11/7/22</td>
                            </tr>
                            <tr>
                                <td class="text-left">St. Anne School
                                </td>
                                <td>I</td>
                                <td>No</td>
                                <td>11/15/22</td>
                            </tr>
                            <tr>
                                <td class="text-left">St. Monica Academy
                                </td>
                                <td>II</td>
                                <td>No</td>
                                <td>10/22/22</td>
                            </tr>
                            <tr>
                                <td class="text-left">St. Norbert School
                                </td>
                                <td>II</td>
                                <td>No</td>
                                <td>10/22/22</td>
                            </tr>
                            <tr>
                                <td class="text-left">Northside Catholic Academy
                                </td>
                                <td>II</td>
                                <td>No</td>
                                <td>10/28/22</td>
                            </tr>
                            <tr>
                                <td class="text-left">Our Lady of Mount Carmel Academy
                                </td>
                                <td>II</td>
                                <td>No</td>
                                <td>10/29/22</td>
                            </tr>
                            <tr>
                                <td class="text-left">St. Malachy School
                                </td>
                                <td>III</td>
                                <td>Yes</td>
                                <td>11/28/22</td>
                            </tr>
                            <tr>
                                <td class="text-left">St. Mary of the Angels School
                                </td>
                                <td>III</td>
                                <td>Yes</td>
                                <td>10/1/22</td>
                            </tr>
                            <tr>
                                <td class="text-left">St. Cletus School
                                </td>
                                <td>IV</td>
                                <td>No</td>
                                <td>10/22/22</td>
                            </tr>
                            <tr>
                                <td class="text-left">St. Constance School

                                </td>
                                <td>IV</td>
                                <td>No</td>
                                <td>10/3/22</td>
                            </tr>
                            <tr>
                                <td class="text-left">St. Barnabas School
                                </td>
                                <td>V</td>
                                <td>No</td>
                                <td>11/20/22</td>
                            </tr>
                            <tr>
                                <td class="text-left">St. Bede the Venerable School
                                </td>
                                <td>V</td>
                                <td>No</td>
                                <td>10/22/22</td>
                            </tr>
                            <tr>
                                <td class="text-left">St. Agnes School
                                </td>
                                <td>VI</td>
                                <td>No</td>
                                <td>11/7/22</td>
                            </tr>
                            <tr>
                                <td class="text-left">St. Ailbe School
                                </td>
                                <td>VI</td>
                                <td>Yes</td>
                                <td>11/7/22</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->
    </div>