import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { map, startWith } from 'rxjs';
import { AjaxService } from '../services/ajax.service'
import { UserService } from '../services/user.service'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  enableFilter: boolean = false
  schools = JSON.parse(localStorage.getItem('schools') || '{}')
  searchText = new FormControl()
  filteredSchools: any
  performance: any = {
    "reading": 0,
    "math": 0
  }
  catholic: any = {
    "stronglyAgree": 0,
    "agree": 0,
    "disagree": 0,
    "stronglyDisagree": 0
  }
  "enrollment": any = {
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0,
    "2022": 0
  }
  "essentials": any = {
    "overallPerformance": "",
    "effectiveLeaders": "",
    "collaborativeTeachers": "",
    "involvedFamilies": "",
    "supportiveEnvironment": "",
    "ambitiousInstruction": ""
  }
  catholicIdentity: any = {}
  academicFlags: any = {}
  essentialsFlags: any = {}
  enrollmentOperationsFlag: any = {}
  averageEnrollment: number = 0
  performanceChart: any
  catholicChart: any
  enrollChart: any
  schoolId: number = 0
  flags: any = {
    "academics": 0,
    "essentials": 0,
    "catholicIdentity": 0,
    "enrollment": 0
  }
  CIScorecards: string = ''
  blueRibbon: any = {}
  currentYear: number = new Date().getFullYear();
  resources: any[] = []

  @ViewChild('academicChart') academicChart: any;
  @ViewChild('enrollmentChart') enrollmentChart: any;
  @ViewChild('identityChart') identityChart: any;
  @ViewChild('searchInput') searchInput: ElementRef<any> | undefined;
  @ViewChild('pdfView') pdfView: ElementRef | undefined;

  constructor(private _ajaxService: AjaxService, private _userService: UserService, private _router: Router, private modalService: NgbModal) {
    this.filteredSchools = this.searchText.valueChanges.pipe(startWith(null), map((text: string | null) => text ? this._filter(text) : this.schools.map((value: any) => ({ ...value }))))
    Chart.register(...registerables);
    Chart.register(ChartDataLabels);
  }

  ngOnInit(): void {
    this.getSchoolsList();
    this.getResources();
    if (this._userService.getSchoolId().value != null) {
      let index = this.schools.findIndex((school: any) => school.schoolId == this._userService.getSchoolId().value);
      this.selectSchool(this.schools[index])
    } else {
      this.selectSchool(this.schools[0]);
    };
  }

  ngAfterViewInit() {
    this.drawPerformanceChart()
    this.drawEnrollChart()
    this.drawCatholicChart()
  }

  getSchoolsList() {
    this._userService.getUserDetails().subscribe((value: any) => {
      if (value != undefined && Object.keys(value).length > 0) {
        const request = {
          "email": value.email
        }
        this._ajaxService.post("users/login", request).then((response: any) => {
          if (response.statusCode !== 200) {
            this._userService.setUserDetails({})
            this._userService.setSchoolId(null)
            this._userService.setUserMenu(false)
            sessionStorage.clear();
            localStorage.clear();
            this._router.navigate(['/login'])
          }
        });
      }
    })
  }

  drawPerformanceChart() {
    let canvas = this.academicChart.nativeElement;
    let ctx = canvas.getContext('2d');
    this.performanceChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Reading 100+ Met', 'Math 100+ Met'],
        datasets: [{
          label: '% Students',
          data: [this.performance.reading, this.performance.math],
          backgroundColor: [
            '#56B1F6',
            '#357FF3',
          ],
          borderColor: [
            '#56B1F6',
            '#357FF3'
          ],
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        scales: {
          xAxis: {
            title: {
              display: true,
              text: '% Progress'
            },
            grid: {
              display: false,
            }
          },
          y: {
            title: {
              display: true,
              text: '% Students'
            },
            grid: {
              display: false
            }
          },
        },
        plugins: {
          legend: { display: false },
          datalabels: {
            formatter: (value, ctx) => {
              return value + "%";
            },
            color: 'black',
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              }
            }
          }
        }
      }
    });
  }

  drawEnrollChart() {
    let canvas = this.enrollmentChart.nativeElement;
    let ctx = canvas.getContext('2d');
    let chartLables = []
    try {
      chartLables = Object.keys(this.enrollment)
    } catch {
      chartLables = ['2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023']
    }
    if (chartLables.length > 11) {
      chartLables = chartLables.slice(chartLables.length - 11)
    }
    this.enrollChart = new Chart(ctx, {
      type: 'bar',
      data: {
        // labels: ['FY 2013', 'FY 2014', 'FY 2015', 'FY 2016', 'FY 2017', 'FY 2018', 'FY 2019', 'FY 2020', 'FY 2021', 'FY 2022', 'FY 2023'],
        labels : chartLables,
        datasets: [{
          label: 'Enrollment',
          data: chartLables.map((key) => this.enrollment[key]),
          backgroundColor: [
            '#87CEEB'
          ],
          borderColor: [
            '#87CEEB'
          ],
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        scales: {
          xAxis: {
            grid: {
              display: false,
            }
          },
          y: {
            grid: {
              display: false
            }
          },
        },
        plugins: { legend: { display: false } }
      }
    });
  }

  drawCatholicChart() {
    let canvas = this.identityChart.nativeElement;
    let ctx = canvas.getContext('2d');
    this.catholicChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [""],
        datasets: [{
          barPercentage: 0.5,
          label: 'Strongly Disagree',
          backgroundColor: "#e62e2d",
          data: [this.catholic.stronglyDisagree],
        }, {
          barPercentage: 0.5,
          label: 'Disagree',
          backgroundColor: "#fdff00",
          data: [this.catholic.disagree],
        }, {
          barPercentage: 0.5,
          label: 'Agree',
          backgroundColor: "#7bd355",
          data: [this.catholic.agree],
        }, {
          barPercentage: 0.5,
          label: 'Strongly Agree',
          backgroundColor: "#4fa716",
          data: [this.catholic.stronglyAgree],
        }],
      },
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        indexAxis: 'y',
        scales: {
          xAxis: {
            stacked: true,
            grid: {
              display: false,
            }
          },
          yAxis: {
            stacked: true,
            grid: {
              display: false,
            }
          }
        },
        plugins: {
          title: {
            display: true,
            text: 'I believe that God is present in my life'
          },
          legend: { display: false },
          datalabels: {
            // formatter: (value, ctx) => {
            //   return value + "%";
            // },
            color: 'black',
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              }
            }
          },
          tooltip: {
            callbacks: {
              label: (Item) => {
                return `${Item.dataset.label}: ${Item.formattedValue}%`
              }
            }
          }
        }
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = String(value).toLowerCase();
    return this.schools.filter((school: any) => ((school.name).toLowerCase()).includes(filterValue));
  }

  selectSchool(school: any) {
    this.searchText.setValue(school.name)
    this.schoolId = school.schoolId
    this._userService.setSchoolId(this.schoolId);
    school.users === true ? this._userService.setUserMenu(true) : this._userService.setUserMenu(false)
    this.closeOptions()
    this.loadWorkbook()
  }

  closeOptions() {
    this.enableFilter = false
    this.searchInput?.nativeElement.blur()
  }

  loadWorkbook() {
    this.performance.reading = 0;
    this.performance.math = 0;
    this.averageEnrollment = 0
    this.essentials = {}
    this.academicFlags = {}
    this.essentialsFlags = {}
    this.catholicIdentity = {}
    this.enrollmentOperationsFlag = {}
    this.flags = {
      "academics": 0,
      "essentials": 0,
      "catholicIdentity": 0,
      "enrollment": 0
    }
    this.CIScorecards = ''
    this.blueRibbon = {}
    const request = { "schoolId": this.schoolId }
    this._ajaxService.post("dashboard/loadWorkbook", request).then((response: any) => {
      if (response.statusCode == 200) {
        if (Object.keys(response.data).length > 0) {
          const info = response.data
          //Academic Performance
          this.performance.reading = Number(info['performance']['reading']);
          this.performance.math = Number(info['performance']['math']);
          this.performanceChart.destroy();
          this.drawPerformanceChart()
          //Catholic Identity
          this.catholic.stronglyAgree = Number(info['catholic']['stronglyAgree'])
          this.catholic.agree = Number(info['catholic']['agree'])
          this.catholic.disagree = Number(info['catholic']['disagree'])
          this.catholic.stronglyDisagree = Number(info['catholic']['stronglyDisagree'])
          this.catholicChart.destroy();
          this.drawCatholicChart()
          //Enrollment
          this.enrollment = info['enrollment']
          this.enrollChart.destroy();
          this.drawEnrollChart();
          //Essentials
          this.averageEnrollment = info['avgEnrollment']
          this.averageEnrollment = this.averageEnrollment ? Math.round(this.averageEnrollment) : this.averageEnrollment;
          this.essentials = info['essentials']
          //Flag Notifications
          this.academicFlags = info['academicFlags']
          this.essentialsFlags = info['essentialsFlags']
          this.catholicIdentity = info['catholicIdentity']
          this.enrollmentOperationsFlag = info['enrollmentOperationsFlag']
          //Enable green/red mark based on flag 'Y'
          Object.keys(this.academicFlags).forEach((key, value) => {
            if (this.academicFlags[key] == 'Y') {
              this.flags['academics']++
            }
          })
          Object.keys(this.essentialsFlags).forEach((key, value) => {
            if (this.essentialsFlags[key] == 'Y') {
              this.flags['essentials']++
            }
          })
          Object.keys(this.catholicIdentity).forEach((key, value) => {
            if (this.catholicIdentity[key] == 'Y') {
              this.flags['catholicIdentity']++
            }
          })
          Object.keys(this.enrollmentOperationsFlag).forEach((key, value) => {
            if (this.enrollmentOperationsFlag[key] == 'Y') {
              this.flags['enrollment']++
            }
          })
          this.CIScorecards = info['CIScorecards']['scoreCardLink'];
          this.blueRibbon = {
            "Spring": info.blueRibbon['BRCSpring'],
            "Fall": info.blueRibbon['Fall'],
            "year" : info.blueRibbon['year']
          }
        } else {
          this.performanceChart.destroy();
          this.drawPerformanceChart()
          this.catholicChart.destroy();
          this.drawCatholicChart()
          this.enrollChart.destroy();
          this.drawEnrollChart();
          this.flags = {
            "academics": 0,
            "essentials": 0,
            "catholicIdentity": 0,
            "enrollment": 0
          }
          this.CIScorecards = ''
          this.blueRibbon = {}
        }
      }
    })
  }

  getResources() {
    this._ajaxService.get('dashboard/getParentResources').then((response: any) => {
      if (response.statusCode == 200) {
        this.resources = response.data
      }
    });
  }

  handleRedirect(path : string) {
    if (path != '' && path != null && path != undefined) {
      if (path.startsWith('/') || !path.startsWith('http')) {
        if (path.includes('enrollment')) {
          this.openPdfFile(this.pdfView, 'xl')
        } else {
          this._router.navigate([path])
        }
      } else {
        window.open(path, "_blank");
      }
    }
  }

  openPdfFile(content: any, size: 'sm' | 'lg' | 'xl') {
    const modalRef = this.modalService.open(content, { centered: true, size });
  }

}