import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AjaxService } from '../services/ajax.service';
import { UserService } from '../services/user.service';
import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

export interface SchoolsTable{
  name: string;
  region: string;
  BSF: string;
  createdOn: string;
}
@Component({
  selector: 'app-action-planning-snapshot',
  templateUrl: './action-planning-snapshot.component.html',
  styleUrls: ['./action-planning-snapshot.component.scss']
})
export class ActionPlanningSnapshotComponent implements OnInit, AfterViewInit {

  noOfSchoolsForAcademic: Number = 0;
  academicData: MatTableDataSource<SchoolsTable> = new MatTableDataSource<SchoolsTable>();
  noOfSchoolsForEssentials: Number = 0;
  essentialsData: MatTableDataSource<SchoolsTable> = new MatTableDataSource<SchoolsTable>();
  noOfSchoolsForCatholic: Number = 0;
  CatholicData: MatTableDataSource<SchoolsTable> = new MatTableDataSource<SchoolsTable>();
  noOfSchoolsForEnrollment: Number = 0;
  enrollmentData: MatTableDataSource<SchoolsTable> = new MatTableDataSource<SchoolsTable>();
  tableColumns: string[] = ['name', 'region', 'BSF', 'createdOn'];
  tableColForNonAP: string[] = ['name', 'region', 'BSF', 'extra']
  fourthColumnName: string = '';
  dataSource: MatTableDataSource<SchoolsTable> = new MatTableDataSource<SchoolsTable>();
  copyOfDataSource:any[] = [];
  enableTable: boolean = false;
  tableHeading: string = '';
  typeOfDataToDisply: string = '';
  actionPlanningSnapShot: boolean = false;
  applliedFilter: string = 'all';
  acdemicYearList:string[] = []
  selectedYear = ""

  @ViewChild('Paginator', { read: MatPaginator }) Paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(private _ajaxService: AjaxService, private _userService: UserService, private _router: Router, private _activeroute: ActivatedRoute) { }

  ngOnInit(): void {
    this._userService.getUserDetails().subscribe((value: any) => {
      if (value != undefined && Object.keys(value).length > 0) {
        if (value.roleId != 5 && value.roleId != 1) {
          this._router.navigate(['/dashboard'])
        }
      }
    });
    this._activeroute.params.subscribe(params => {
      const receivedData = params['data'];
      if (receivedData === 'actionPlanning') {
        this.actionPlanningSnapShot = true;
        this.getSchoolsData();
      } else {
        this.typeOfDataToDisply = receivedData;
        this.applliedFilter = params['filter']
        this.getSchoolsList();
      }
    });
  }

  ngAfterViewInit(): void {}

  /**
   * @author Manjunath
   * @uses to filter table on search 
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * @author Manjunath
   * @uses to change table. 
   */
  handleTableChange(type: String) {
    this.enableTable = true;
    if (type === 'academic') {
      this.tableHeading = 'Academic Action Plans Submitted'
      this.dataSource = this.academicData
    } else if (type === 'essential') {
      this.tableHeading = '5 Essentials Action Plans Submitted'
      this.dataSource = this.essentialsData
    } else if (type === 'catholic') {
      this.tableHeading = 'Catholic Identity Action Plans Submitted'
      this.dataSource = this.CatholicData
    } else if (type === 'enrollment'){
      this.tableHeading = 'Enrollment & Finance Action Plans Submitted'
      this.dataSource = this.enrollmentData
    } else {
      this.enableTable = false;
    }
    setTimeout(() => this.dataSource.paginator = this.Paginator);
    setTimeout(() => this.dataSource.sort = this.sort);
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string | number) => {
      if (data[sortHeaderId] != null) {
        switch (sortHeaderId) {
          case 'createdOn': return new Date(data.createdOn).getTime();
          default: try {
            return data[sortHeaderId].toLowerCase();
          } catch {
            return data[sortHeaderId];
          }
        }
      }
    };
  }

  /**
   * @author Manjunath
   * @uses to change table data on change of academic year. 
   */
  handleAcademicYearChange(value : string) {
    this.selectedYear = value;
    this.dataSource.data = this.copyOfDataSource;
    this.updateDataToTable(this.copyOfDataSource, value)
  }

  /**
   * @author Manjunath
   * @uses  to calculate Academic Year
   * @returns academic_year
   */
  getAcademicYear(firstHalf:any) {
    const SECOND_HALF = firstHalf + 1
    const academic_year = firstHalf.toString() + '-' + SECOND_HALF.toString().slice(-2)
    return academic_year
  }

  /**
   * @author Manjunath
   * @uses to set data to table.
   */
  updateDataToTable(data:any, academicyear:any) {
    const types = ['academics', 'essentials', 'catholic', 'enrollment'];
    const value_list = academicyear.split('-')
    const year = parseInt(value_list[0], 10);
    const year2 = parseInt(value_list[1], 10) + 2000;
    const dataSources = types.map(type => {
      const filteredData = data.filter((school: any) => school.type === type && new Date(school.createdOn) > new Date(year, 5, 1) && new Date(school.createdOn) < new Date(year2, 5, 1));
      return new MatTableDataSource<SchoolsTable>(filteredData);
    });

    this.academicData = dataSources[0];
    this.essentialsData = dataSources[1];
    this.CatholicData = dataSources[2];
    this.enrollmentData = dataSources[3];

    if (this.tableHeading === '') {
      this.dataSource = new MatTableDataSource<SchoolsTable>(data);
    } else {
      if (this.tableHeading.toLowerCase().includes("academic")) {
        this.dataSource = this.academicData
      } else if (this.tableHeading.toLowerCase().includes("essentials")) {
        this.dataSource = this.essentialsData
      } else if (this.tableHeading.toLowerCase().includes("catholic")) {
        this.dataSource = this.CatholicData
      } else if (this.tableHeading.toLowerCase().includes("enrollment")) {
        this.dataSource = this.enrollmentData
      } else {
        this.dataSource.data = [];
      }
    }
    const noOfSchools = dataSources.map(ds => ds.data.length);

    [this.noOfSchoolsForAcademic, this.noOfSchoolsForEssentials, this.noOfSchoolsForCatholic, this.noOfSchoolsForEnrollment] = noOfSchools;
    setTimeout(() => this.dataSource.paginator = this.Paginator);
    setTimeout(() => this.dataSource.sort = this.sort);
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string | number) => {
      if (data[sortHeaderId] != null) {
        switch (sortHeaderId) {
          case 'createdOn': return new Date(data.createdOn).getTime();
          default: try {
            return data[sortHeaderId].toLowerCase();
          } catch {
            return data[sortHeaderId];
          }
        }
      }
    };
  }


  /**
   * @author Manjunath
   * @uses to get data for Action Planning Snapshot
   */
  getSchoolsData() {
    this.actionPlanningSnapShot = true;
    this._ajaxService.get('admin/getActionPlanningSnapshot').then((response: any) => {
      if (response.statusCode == 200) {
        this.copyOfDataSource = response.data;
        const CURRENT_DATE = new Date();
        const CURRENT_YEAR = CURRENT_DATE.getFullYear();
        const DIFFERENCE_YEARS = CURRENT_YEAR - 2022;
        let previous_year = new Date().getFullYear() - DIFFERENCE_YEARS
        for (let i = 0; i < DIFFERENCE_YEARS; i++) {
          const academic_year = this.getAcademicYear(previous_year)
          this.acdemicYearList.push(academic_year)
          previous_year += 1
        }
        const june1st = new Date(CURRENT_YEAR, 5, 1)
        if (CURRENT_DATE > june1st) {
          const academic_year = this.getAcademicYear(CURRENT_YEAR)
          this.acdemicYearList.push(academic_year)
        }
        if (!this.selectedYear) this.selectedYear = this.acdemicYearList[this.acdemicYearList.length - 1]
        this.updateDataToTable(response.data, this.selectedYear)
      }
    });    
  }

  /**
   * @author Manjunath
   * @uses to to merge different arrays on schoolId
   */
  combineArrays(...arrays: any[][]): any[] {
    const combinedMap = new Map<number, any>();
    arrays.forEach(arr => {
      arr.forEach((obj: any) => {
        let schoolId = obj.schoolId;
        if (schoolId == undefined) schoolId = obj.schoolID
        if (!combinedMap.has(schoolId)) {
          combinedMap.set(schoolId, { schoolId });
        }
        combinedMap.set(schoolId, { ...combinedMap.get(schoolId), ...obj });
      });
    });
    return Array.from(combinedMap.values());
  }

  /**
   * @author Manjunath
   * @uses to get the cell data for fourth column (extra)  
   */
  getDataForFourthColumn(element: any) {
    switch(this.fourthColumnName) {
      case 'Overall Performance':
        return element.overAllPerformance;
      case 'Average Enrollment':
        return element['Avg. Enrollment in K-2']
      case 'Strongly Agree':
        return element.stronglyAgree
      case 'Reading Growth':
        return element.readingGrowth
      case 'Math Growth':
        return element.mathGrowth
    }
  }

  /**
   * @author Manjunath
   * @uses set data to table.
   */
  createTable(responseData: any) {
    if (responseData) {
      const combinedArray: any[] = this.combineArrays(responseData['Schools'], 
      responseData['Academic Performance'], 
      responseData['5 Essentials Performance Summary'],
      responseData['Catholic Identity Summary - I believe that God is present in my life'],
      responseData['Enrollment Summary'],
      );
      let filterCondition;
      let tableHeading;

      switch (this.typeOfDataToDisply) {
        case 'essentials':
          if (this.applliedFilter != 'all') {
            if (this.applliedFilter === 'BSF') {
              filterCondition = (item: any) => (item.overAllPerformance === 'Organized' || item.overAllPerformance === 'Well-Organized') && (item.BSF === 'Y');
            } else {
              filterCondition = (item: any) => (item.overAllPerformance === 'Organized' || item.overAllPerformance === 'Well-Organized') && (item.region === this.applliedFilter);
            }
          } else {
            filterCondition = (item: any) => item.overAllPerformance === 'Organized' || item.overAllPerformance === 'Well-Organized';
          }
          tableHeading = 'Schools With Overall Performance Organized or Well-Organized';
          this.fourthColumnName = 'Overall Performance'
          break;

        case 'enrollment':
          if (this.applliedFilter != 'all') {
            if (this.applliedFilter === 'BSF') {
              filterCondition = (item: any) => (item['Avg. Enrollment in K-2'] < 20) && (item.BSF === 'Y');
            } else {
              filterCondition = (item: any) => (item['Avg. Enrollment in K-2'] < 20) && (item.region === this.applliedFilter);
            }
          } else {
            filterCondition = (item: { [x: string]: number; }) => item['Avg. Enrollment in K-2'] < 20;
          }
          tableHeading = 'Schools With Average Enrollment < 20';
          this.fourthColumnName = 'Average Enrollment'
          break;

        case 'catholic':
          if (this.applliedFilter != 'all') {
            if (this.applliedFilter === 'BSF') {
              filterCondition = (item: any) => (item['stronglyAgree'] >= 75) && (item.BSF === 'Y');
            } else {
              filterCondition = (item: any) => (item['stronglyAgree'] >= 75) && (item.region === this.applliedFilter);
            }
          } else {
            filterCondition = (item: { [x: string]: number; }) => item['stronglyAgree'] >= 75;
          }
          tableHeading = 'Schools With >75% of students “Strongly Agree” that “God is present in their life”';
          this.fourthColumnName = 'Strongly Agree'
          break;

        case 'reading':
          if (this.applliedFilter != 'all') {
            if (this.applliedFilter === 'BSF') {
              filterCondition = (item: any) => (item['readingGrowth'] >= 75) && (item.BSF === 'Y');
            } else {
              filterCondition = (item: any) => (item['readingGrowth'] >= 75) && (item.region === this.applliedFilter);
            }
          } else {
            filterCondition = (item: { [x: string]: number; }) => item['readingGrowth'] >= 75;
          }
          tableHeading = 'Schools With I-Ready Reading % Meeting Typical Annual Growth > 75%';
          this.fourthColumnName = 'Reading Growth'
          break;

        case 'math':
          if (this.applliedFilter != 'all') {
            if (this.applliedFilter === 'BSF') {
              filterCondition = (item: any) => (item['mathGrowth'] >= 75) && (item.BSF === 'Y');
            } else {
              filterCondition = (item: any) => (item['mathGrowth'] >= 75) && (item.region === this.applliedFilter);
            }
          } else {
            filterCondition = (item: { [x: string]: number; }) => item['mathGrowth'] >= 75;
          }
          tableHeading = 'Schools With I-Ready Math % Meeting Typical Annual Growth > 75%';
          this.fourthColumnName = 'Math Growth'
          break;

        default:
          filterCondition = () => true;
          tableHeading = '';
      }

      this.tableHeading = tableHeading;
      this.dataSource = new MatTableDataSource<SchoolsTable>(combinedArray.filter(filterCondition));
      setTimeout(() => this.dataSource.paginator = this.Paginator);
      setTimeout(() => this.dataSource.sort = this.sort);

      this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string | number) => {
        if (data[sortHeaderId] != null) {
          switch (sortHeaderId) {
            case 'date': return new Date(data.date).getTime();
            default: try {
              return data[sortHeaderId].toLowerCase();
            } catch {
              return data[sortHeaderId];
            }
          }
        } else if(data[sortHeaderId] == null) {
          switch(this.fourthColumnName) {
            case 'Overall Performance':
              return data['overAllPerformance']
            case 'Average Enrollment':
              return data['Avg. Enrollment in K-2']
            case 'Strongly Agree':
              return data.stronglyAgree
            case 'Reading Growth':
              return data.readingGrowth
            case 'Math Growth':
              return data.mathGrowth
            default:
              return
          }
        }
      };
    }
  }

  /**
   * @author Manjunath
   * @uses to get data for tables from Admin Dashboard
   */
  getSchoolsList() {
    this.actionPlanningSnapShot = false;
    const storedData = sessionStorage.getItem('AllSchoolsData');
    if (storedData) {
      // this.createTable(JSON.parse(storedData));
      setTimeout(() => this.createTable(JSON.parse(storedData)));
    } else {
      this._ajaxService.get('admin/getAllSchoolsData').then((response: any) => {
        if (response.statusCode == 200) {
          this.createTable(response.data)
        }
      })
    }
  }

}
