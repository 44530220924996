import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service'
import { Router } from '@angular/router'
import { AjaxService } from '../services/ajax.service'
import { Subscription } from 'rxjs'
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-goal',
  templateUrl: './goal.component.html',
  styleUrls: ['./goal.component.scss']
})
export class GoalComponent implements OnInit {

  schoolId: number = 0
  priorityType: string = 'academics'
  userSubscription: Subscription[] = []
  priorityAreaForm: FormGroup = this._formBuilder.group({
    areas: this._formBuilder.array([])
  })
  priorityQuestions: Array<string> = ['What is your goal?', 'What is your action plan?']
  academicYearGuiding: Array<string> = ['What is your Priority', 'What is your plan for this priority?']
  catholicIdentityGuiding: Array<string> = ['What is your Priority', 'What is your plan for this priority?']
  enrollmentGuiding: Array<string> = ['What is your Priority', 'What is your plan for this priority?']
  essentialsGuiding: Array<string> = ['What is your Priority', 'What is your plan for this priority?']
  enableMsg: boolean = false;
  priorityAreaId: number = 0;
  academicDate: String = '';
  isActive1: boolean = true;
  isActive2: boolean = false;
  isActive3: boolean = false;
  validation: boolean = false;
  meetingDataGuide: any = {};
  enableYear: boolean = false;
  yearCount: number = 1;
  slectedIndex: number = 0;
  

  @ViewChild('mymodal') mymodal: ElementRef | undefined;
  @ViewChild('expander') expander: ElementRef | undefined;

  constructor(private _userService: UserService, private _router: Router, private _ajaxService: AjaxService, private _formBuilder: FormBuilder, private modalService: NgbModal, private changeDetector: ChangeDetectorRef, private _snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.userSubscription.push(this._userService.getSchoolId().subscribe(value => {
      if (value != null) {
        this.schoolId = value
        this.getPlanningItems()
        this.getCurrentAcademicYear();
        this.loadWorkBook();
      } else {
        this._router.navigate(['/dashboard'])
      }
    }))
  }

  /**
   * @author Nagendra
   * @uses To get planning items
   */
  getPlanningItems() {
    const request = {
      "priorityType": this.priorityType,
      "schoolId": this.schoolId
    }
    this._ajaxService.post("planning/items", request).then((response: any) => {
      if (response.statusCode == 200) {
        if (Object.keys(response.data).length > 0) {
          this.enableYear = true;
          // this.yearCount = Object.keys(response.data).length;
          // this.yearCount == 1 ? this.reset() : this.yearCount == 2 ? this.reset1() : this.reset2()
          this.priorityAreaForm = this._formBuilder.group({
            areas: this._formBuilder.array([])
          })
          Object.keys(response.data).forEach((goal: any, goalIndex: number) => {
            this.getAreasArray.push(this._formBuilder.group({
              "id": Number(goal),
              "year1": this._formBuilder.array([]),
              "year2": this._formBuilder.array([]),
              "year3": this._formBuilder.array([])
            }));
            this.addYear(goalIndex, response.data[goal]['year1'], response.data[goal]['year2'], response.data[goal]['year3'])
          })
        } else {
          this.enableYear = false;
          this.yearCount = 1;
          this.priorityAreaForm = this._formBuilder.group({
            areas: this._formBuilder.array([])
          })
        }
      }
    })
  }

  /**
   * @author Nagendra
   * @uses To save priority areas
   */
  saveActionItems() {
    this.modalService.dismissAll()
    const request = {
      "priorityType": this.priorityType,
      "schoolId": this.schoolId,
      "areas": this.priorityAreaForm.value['areas']
    }
    this._ajaxService.post("planning/save", request).then((response: any) => {
      if (response.statusCode == 200) {
        this.yearCount = 1;
        this._snackbar.open("Priorities saved successfully !", "OK", { duration: 3000, panelClass: "success-dialog" });
        // this.enableMsg = true
        // setTimeout(() => {
        //   this.enableMsg = false
        // }, 3000)
        this.getPlanningItems();
      } else {
        this._snackbar.open(`${response.error.message}`, "OK", { duration: 3000, panelClass: "warning-dialog" })
      }
    })
  }

  /**
   * @author Nagendra
   * @uses To delete priority area
   */
  deletePriorityArea() {
    const request = {
      "schoolId": this.schoolId,
      "priorityAreaId": this.priorityAreaId
    }
    this._ajaxService.post("planning/delete", request).then((response: any) => {
      if (response.statusCode == 200) {
        this.modalService.dismissAll();
        this.getPlanningItems();
      }
    })
  }

  get getAreasArray() {
    return <FormArray>this.priorityAreaForm.get('areas')
  }

  get getAreasControls() {
    return (this.priorityAreaForm.get('areas') as FormArray).controls
  }

  priorityAreaYears(index: number, year: string): FormArray {
    return this.getAreasArray.at(index).get(year) as FormArray;
  }

  priorityAreaObjects(index: number, year: string): FormArray {
    return this.getAreasArray.at(index).get(year) as FormArray;
  }

  addArea() {
    if (this.getAreasArray.status == 'VALID') {
      this.enableYear = true;
      //  this.yearCount = 1;
      if (this.yearCount == 1) {
        this.getCurrentAcademicYear()
      }
      this.getAreasArray.push(this._formBuilder.group({
        "id": null,
        "isActive1": true,
        "isActive2": false,
        "isActive3": false,
        "year1": this._formBuilder.array([
          this._formBuilder.group({
            "year": "year1",
            "id": [null],
            "value": [""],
            "sequence": [1]
          }),
          this._formBuilder.group({
            "year": "year1",
            "id": [null],
            "value": [""],
            "sequence": [2]
          }),
        ]),
        "year2": this._formBuilder.array([
          this._formBuilder.group({
            "year": "year2",
            "id": [null],
            "value": [""],
            "sequence": [1]
          }),
          this._formBuilder.group({
            "year": "year2",
            "id": [null],
            "value": [""],
            "sequence": [2]
          }),
        ]),
        "year3": this._formBuilder.array([
          this._formBuilder.group({
            "year": "year3",
            "id": [null],
            "value": [""],
            "sequence": [1]
          }),
          this._formBuilder.group({
            "year": "year3",
            "id": [null],
            "value": [""],
            "sequence": [2]
          }),
        ]),
      }))
    }
  }

  /**
   * @author Nagendra
   * @uses To update based on select item
   */
  updateAction() {
    this.getPlanningItems();
  }

  getPlaceholder(year: string, index: number) {
    return this.priorityType == 'academics' ? (this.academicYearGuiding[index]).replace('Year 1', year) : (this.priorityType == 'catholic' ? (this.catholicIdentityGuiding[index]).replace('Year 1', year) : this.priorityType == 'enrollment' ? (this.enrollmentGuiding[index]).replace('Year 1', year) : this.priorityType == 'essentials' ? (this.essentialsGuiding[index]).replace('Year 1', year) : '');
  }

  getQuestion(index: number) {
    return this.priorityQuestions[index]
  }
  addYear(index: number, year1: any, year2: any, year3: any) {
    year1.forEach((year: any) => {
      ((this.priorityAreaForm.get('areas') as FormArray).at(index).get('year1') as FormArray).push(
        this._formBuilder.group({
          "year": [year.year],
          "id": [year.id],
          "value": [year.value],
          "sequence": [year.sequence]
        }));
    })
    year2.forEach((year: any) => {
      ((this.priorityAreaForm.get('areas') as FormArray).at(index).get('year2') as FormArray).push(
        this._formBuilder.group({
          "year": [year.year],
          "id": [year.id],
          "value": [year.value],
          "sequence": [year.sequence]
        }));
        if (year.value != '' && year.value != null && year.sequence == 1) {
          this.enableYear = true
          this.yearCount += 1
        }
    })
    year3.forEach((year: any) => {
      ((this.priorityAreaForm.get('areas') as FormArray).at(index).get('year3') as FormArray).push(
        this._formBuilder.group({
          "year": [year.year],
          "id": [year.id],
          "value": [year.value],
          "sequence": [year.sequence]
        }));
        if (year.value != '' && year.value != null && year.sequence == 1) {
          this.enableYear = true
          this.yearCount += 1
        }
    })
    if (this.yearCount > 3) {
      this.yearCount = 3;
    }
  }

  /**
   * @author Nagendra
   * @param content 
   */
  open(content: any, index: number) {
    this.priorityAreaId = Number((this.getAreasArray.at(index) as FormGroup).controls['id'].value)
    if (this.priorityAreaForm.value.areas[index].id == null) {
      this.getAreasArray.removeAt(index)
      this.changeDetector.detectChanges();
    }
    else {
      this.modalService
        .open(content, { ariaLabelledBy: 'modal-basic-title' })
        .result.then(
          (result) => { }, (reason) => { });
    }
  }

  /**
  * @author Gopi
  * @uses to get academic year 
  */
  getCurrentAcademicYear() {
    let currentYear = new Date().getFullYear();
    let nextYear = new Date().getFullYear() + 1;
    let curyear = currentYear.toString();
    let nexYear = nextYear.toString();
    let joinedString = this.joinSplittedValues([2, 3], nexYear)
    this.academicDate = curyear + "-" + joinedString
  }

  /**
  * @author Gopi
  * @uses to join split values 
  */
  joinSplittedValues(positionsToConcat: any, value: any) {
    let result = positionsToConcat.map((position: any) => value[position]).join("");
    return result;
  }

  /**
  * @author Gopi
  * @uses to get next year academics
  */
  getNextYearAcademicDate(year: any) {
    if (year == 'year1') {
      let currentYear = new Date().getFullYear() + 1
      let nextYear = new Date().getFullYear() + 2
      let curyear = currentYear.toString();
      let nexYear = nextYear.toString();
      let joinedString = this.joinSplittedValues([2, 3], nexYear)
      this.academicDate = curyear + "-" + joinedString
    }
    else if (year == 'year2') {
      let currentYear = new Date().getFullYear() + 2
      let nextYear = new Date().getFullYear() + 3
      let curyear = currentYear.toString();
      let nexYear = nextYear.toString();
      let joinedString = this.joinSplittedValues([2, 3], nexYear)
      this.academicDate = curyear + "-" + joinedString
    }
  }

  /**
  * @author Gopi
  * @uses to get work book data
  * @param schoolId
  */
  loadWorkBook() {
    let request = {
      'schoolId': this.schoolId
    }
    this._ajaxService.post("dashboard/loadWorkbook", request).then((response: any) => {
      if (response.statusCode == 200) {
        if (Object.keys(response.data).length > 0) {
          const info = response.data
          this.meetingDataGuide = info['dataMeetingGuide'];
        }
      }
    })
  }

  /**
  * @author Gopi
  * @uses to enable next year priority areas
  */
  updateNextYear() {
    if (this.yearCount != 3) {
      this.yearCount++;
    }
    let currentYear = new Date().getFullYear();
    let currentNextYear = new Date().getFullYear() + 1;
    if (this.academicDate.includes(currentYear.toString())) {
      this.isActive1 = false;
      this.getNextYearAcademicDate('year1')
      this.isActive2 = true;
      this.isActive3 = false;
    }
    else if (this.academicDate.includes(currentNextYear.toString())) {
      this.isActive1 = false;
      this.isActive2 = false;
      this.getNextYearAcademicDate('year2');
      this.isActive3 = true;
    }
  }

  /**
  * @author Gopi
  * @uses to navigate next years slides
  */
  navigateToYears(year: any) {
    if (year == 'year1') {
      this.reset();
    }
    else if (year == 'year2') {
      this.isActive1 = false;
      this.getNextYearAcademicDate('year1');
      this.isActive2 = true;
      this.isActive3 = false;
    }
    else if (year == 'year3') {
      this.isActive1 = false;
      this.isActive2 = false;
      this.getNextYearAcademicDate('year2');
      this.isActive3 = true;
    }
  }

  /**
  * @author Gopi
  * @uses to reset the slide controls
  */
  reset() {
    this.getCurrentAcademicYear();
    this.isActive1 = true;
    this.isActive2 = false;
    this.isActive3 = false;
  }

  reset1() {
    this.isActive1 = false;
    this.getNextYearAcademicDate('year1');
    this.isActive2 = true;
    this.isActive3 = false;
  }

  reset2() {
    this.isActive1 = false;
    this.isActive2 = false;
    this.getNextYearAcademicDate('year2');
    this.isActive3 = true;
  }

  /**
  * @author Manjunath
  * @uses to pop-up priority area
  */
  expand(content: any, size: 'sm' | 'lg' | 'xl', index: number) {
    this.slectedIndex = index;
    const modalRef = this.modalService.open(content, { centered: true, size });
    modalRef.result.then((result) => {
      this.changeDetector.detectChanges();
    }, () => {
      for (let i=0; i<this.getAreasControls.length; i++) {
        this.getAreasControls[i].patchValue(this.getAreasControls[i].value)
      }
      this.changeDetector.detectChanges();
    });
  }
  
  /**
  * @author Manjunath
  * @uses to navigate between Priority Area's
  */
  navigationForPriorityArea(action: string) {
    if (action === 'prev') {
      if (this.slectedIndex != 0) {
        this.slectedIndex -= 1
      }
    } else {
      if (this.slectedIndex < this.getAreasControls.length - 1) {
        this.slectedIndex += 1
      }
    }
  }  

  ngOnDestroy() {
    this.userSubscription.forEach(value => {
      value.unsubscribe();
    })
  }

}
