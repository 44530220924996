<section>
    <div class="container-fluid bg-colorgrey">
        <div class="row ml-0 mr-0 mt-3">
            <div class="col-md-12">
                <div class="row m-0">
                    <div class="col-md-12 pl-0">
                        <div class="text-left">
                            <div class="system-head pl-2">See It - Name It - Do It
                            </div>
                            <div class="parastem" style="list-style-type: none; text-align: justify;">
                                <li style="padding-bottom: 10px;">On this page, schools will fill in <strong>at least ONE Priority Area Goal and Action Plan.</strong> You may choose to have ONE Priority Area Goal for Academics, Enrollment, Catholic Identity, or 5 Essentials. If you would like to add more than one, you may, that is optional.</li>
                                <li style="padding-bottom: 10px;">Please click on the "Data Meeting Guide" button to be redirected to a Google Doc that hyperlinks to the Data Meeting Guide template. Feel free to use this template to assist you with examining your data and identifying your updated actions. You may type directly into this document. Any users you add to the CSIP will have access to your Google Doc.</li>
                                <li style="padding-bottom: 10px;">After completing the Data Meeting Guide template with your school team, go to the boxes below. Indicate your Priority Area Goal and Action Plan. Your Priority Area Goal should be 1) bite-sized, 2) focuses on the highest lever, and 3) is measurable. Your action plan should be copied and pasted from the Data Meeting Guide (it is the answer to the prompt:"Rewrite your Refocus plan:",that is in the DO IT section.)</li>
                                <li style="padding-bottom: 10px;">If you would like to add additional Priority Area Goals, click on the "Add Priority" button to add your goals and actions.</li>
                                <li style="padding-bottom: 10px;">Please note: schools are required to have <strong>ONE</strong> Priority Area. Having more than one is optional.</li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-3">
                <button class="btn btn-primary" (click)="addArea()">Add Priority Area</button>
            </div>
            <div class="col-md-3 text-right">
                <button class="btn mt-0">
                    <a class="btn btn-blue-my" target="_blank" [href]="meetingDataGuide">
                        Data Meeting Guide
                    </a>
                </button>
            </div>
           
            <div class="col-md-3 mt-2">
                <select class="form-select" [(ngModel)]="priorityType" (change)="updateAction()">
                    <option value="academics">Academic</option>
                    <option value="catholic">Catholic Identity</option>
                    <option value="enrollment">Enrollment & Finance</option>
                    <option value="essentials">5Essentials</option>
                </select>
            </div>
        </div>
        <form [formGroup]="priorityAreaForm">
            <div formArrayName="areas">
                <div class="text-center mt-2" *ngIf="enableYear"><strong>SY {{academicDate}}</strong></div>
                <div class="paddingboxer" *ngFor="let area of getAreasControls;let i = index" [formGroupName]="i">
                    <div class="row ml-0 mr-0 mt-1">
                        <div class="col-md-8">
                            <div class="heading-goal">PRIORITY AREA {{i+1}} </div>
                        </div>
                        <div class="col-md-4 text-right">
                            <a ngbTooltip="Maximize" class="deleting tooltip-h" (click)="expand(expander, 'xl', i)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style=" fill:#000;" class="bi bi-arrows-fullscreen" viewBox="0 0 18 18">
                                    <path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"/>
                                </svg>
                            </a>
                            <span class="icon-spacing"></span>
                            <a ngbTooltip="Delete" class="deleting tooltip-h" (click)="open(mymodal, i)">
                                <svg x="0px" y="0px" width="30" height="30" viewBox="0 0 30 30" style=" fill:#000;">
                                    <path
                                        d="M 10 2 L 9 3 L 3 3 L 3 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 4.3652344 7 L 6.0683594 22 L 17.931641 22 L 19.634766 7 L 4.3652344 7 z">
                                    </path>
                                </svg>
                            </a>
                        </div>
                        <div class="col-md-12">
                            <div id="myCarousel" class="carousel slide">
                                <div class="carousel-inner">
                                    <div class="carousel-item" [class.active]="isActive1">
                                        <div class="col-md-12" formArrayName="year1">
                                            <div *ngFor="let year of priorityAreaYears(i, 'year1').controls;let y1 = index"
                                                [formGroupName]="y1">
                                                <span>{{getQuestion(y1)}}</span>
                                                <textarea type="text" class="goal-boxer" formControlName=value
                                                    maxlength="65535"
                                                    [placeholder]="getPlaceholder('Year 1', y1)"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item" [class.active]="isActive2">
                                        <div class="col-md-12" formArrayName="year2">
                                            <div *ngFor="let year of priorityAreaYears(i, 'year2').controls;let y2 = index"
                                                [formGroupName]="y2">
                                                <span>{{getQuestion(y2)}}</span>
                                                <textarea type="text" class="goal-boxer" formControlName=value
                                                    maxlength="65535"
                                                    [placeholder]="getPlaceholder('Year 2', y2)"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item" [class.active]="isActive3">
                                        <div class="col-md-12" formArrayName="year3">
                                            <div *ngFor="let year of priorityAreaYears(i, 'year3').controls;let y3 = index"
                                                [formGroupName]="y3">
                                                <span>{{getQuestion(y3)}}</span>
                                                <textarea type="text" class="goal-boxer" formControlName=value
                                                    maxlength="65535"
                                                    [placeholder]="getPlaceholder('Year 3', y3)"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="carousel-indicators posinion" *ngIf="enableYear && yearCount == 2">
                    <li class="year1" [class.active]="isActive1" (click)="navigateToYears('year1')">
                    </li>
                    <li class="year2" [class.active]="isActive2" (click)="navigateToYears('year2')">
                    </li>
                    <!-- <li class="year3" [class.active]="isActive3" (click)="navigateToYears('year3')">
                    </li> -->
                </ul>
                <ul class="carousel-indicators posinion" *ngIf="enableYear && yearCount == 3">
                    <li class="year1" [class.active]="isActive1" (click)="navigateToYears('year1')">
                    </li>
                    <li class="year2" [class.active]="isActive2" (click)="navigateToYears('year2')">
                    </li>
                    <li class="year3" [class.active]="isActive3" (click)="navigateToYears('year3')">
                    </li>
                </ul>
            </div>
            <div class="row m-0">
                <div class="col-md-4 offset-md-4 text-center">
                    <div class="alert alert-success" *ngIf="enableMsg">
                        <div>Success!! Priorities saved.</div>
                    </div>
                </div>
                <div class="col-md-4 text-right" *ngIf="getAreasControls.length > 0">
                    <span *ngIf="yearCount != 3">
                        <button class="btn btn-secondary" type="button" (click)="updateNextYear()">Add
                            Year</button>
                    </span>
                    <span class="pl-2">
                        <button class="btn btn-primary" type="button" (click)="saveActionItems()">Save</button>
                    </span>
                </div>
            </div>
        </form>
    </div>
</section>

<ng-template #mymodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Priority Area</h4>
        <button type="button" class="close-button" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure you want to delete ?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close()">No</button>
        <button type="button" class="btn btn-primary" (click)="deletePriorityArea()">Yes</button>
    </div>
</ng-template>

<ng-template #expander let-modal>
    <div class="modal-header text-right">
        <h4 class="modal-title" id="modal-basic-title"><strong>SY {{academicDate}}</strong></h4>
        <button type="button" class="close-button" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="priorityAreaForm">
            <div formArrayName="areas">
                <div *ngFor="let area of getAreasControls;let i = index" [formGroupName]="i">
                    <ng-container *ngIf="i === slectedIndex">
                        <div class="row mb-1">
                            <div class="col" *ngIf="getAreasControls.length > 1">
                                <svg ngbTooltip="Previous" style="cursor: pointer; fill:#417ee6" (click)="navigationForPriorityArea('prev')" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 512 512">
                                    <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160zm352-160l-160 160c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L301.3 256 438.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0z"/>
                                </svg>
                            </div>
                            <div class="text-center" [ngClass]="getAreasControls.length > 1 ? 'col-md-10' : 'col-md-12'">
                                <span style="text-decoration: underline;"><strong>PRIORITY AREA {{i+1}}</strong></span>
                            </div>
                            <div class="col text-right" *ngIf="getAreasControls.length > 1">
                                <svg ngbTooltip="Next" style="cursor: pointer; fill:#417ee6" (click)="navigationForPriorityArea('next')" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 512 512">
                                    <path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z"/>
                                </svg>
                            </div>
                        </div>
                        <div class="row ml-0 mr-0">
                            <div class="col-md-12">
                                <div id="myCarousel" class="carousel slide">
                                    <div class="carousel-inner">
                                        <div class="carousel-item" [class.active]="isActive1">
                                            <div class="col-md-12" formArrayName="year1">
                                                <div *ngFor="let year of priorityAreaYears(i, 'year1').controls;let y1 = index"
                                                    [formGroupName]="y1">
                                                    <span>{{getQuestion(y1)}}</span>
                                                    <textarea type="text" rows="7" class="goal-boxer" formControlName=value
                                                        maxlength="65535"
                                                        [placeholder]="getPlaceholder('Year 1', y1)"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item" [class.active]="isActive2">
                                            <div class="col-md-12" formArrayName="year2">
                                                <div *ngFor="let year of priorityAreaYears(i, 'year2').controls;let y2 = index"
                                                    [formGroupName]="y2">
                                                    <span>{{getQuestion(y2)}}</span>
                                                    <textarea type="text" rows="7" class="goal-boxer" formControlName=value
                                                        maxlength="65535"
                                                        [placeholder]="getPlaceholder('Year 2', y2)"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item" [class.active]="isActive3">
                                            <div class="col-md-12" formArrayName="year3">
                                                <div *ngFor="let year of priorityAreaYears(i, 'year3').controls;let y3 = index"
                                                    [formGroupName]="y3">
                                                    <span>{{getQuestion(y3)}}</span>
                                                    <textarea type="text" rows="7" class="goal-boxer" formControlName=value
                                                        maxlength="65535"
                                                        [placeholder]="getPlaceholder('Year 3', y3)"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <ul class="carousel-indicators posinion" *ngIf="enableYear && yearCount == 2">
                    <li class="year1" [class.active]="isActive1" (click)="navigateToYears('year1')">
                    </li>
                    <li class="year2" [class.active]="isActive2" (click)="navigateToYears('year2')">
                    </li>
                </ul>
                <ul class="carousel-indicators posinion" *ngIf="enableYear && yearCount == 3">
                    <li class="year1" [class.active]="isActive1" (click)="navigateToYears('year1')">
                    </li>
                    <li class="year2" [class.active]="isActive2" (click)="navigateToYears('year2')">
                    </li>
                    <li class="year3" [class.active]="isActive3" (click)="navigateToYears('year3')">
                    </li>
                </ul>
            </div>
            <div class="row m-0">
                <div class="col-md-4 offset-md-4 text-center">
                    
                </div>
                <div class="col-md-4 text-right" *ngIf="getAreasControls.length > 0">
                    <span *ngIf="yearCount != 3">
                        <button class="btn btn-secondary" type="button" (click)="updateNextYear()">Add
                            Year</button>
                    </span>
                    <span class="pl-2">
                        <button class="btn btn-primary" type="button" (click)="saveActionItems()">Save</button>
                    </span>
                </div>
            </div>
        </form>
    </div>
</ng-template>