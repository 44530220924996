<section>
    <div class="container-fluid bg-colorgrey">
        <div class="row m-0">
            <div class="col-md-9">
                <div class="row m-0">
                    <div class="col-md-12">
                        <div class="text-left">

                            <ul class="ul-decial">
                                <span class="system-head">System-Wide Goals</span>
                                <li>Each school will be rated Strong on at least 3/5 Essentials, including Supportive Environment, at the conclusion of 2024-25</li>

                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-3">
                <!-- <div class="getlowest_filds">
                    <ul>
                        <li>
                            <span class="position-relative top15" (mouseleave)="closeOptions()">
                                <label>Select School</label><br />
                                <input type="text" class="comment_fild getlowest_fild fild_bg"
                                    [formControl]="searchText" (focus)="enableFilter = true" #searchInput />
                                <div class="search-lister" *ngIf="enableFilter">
                                    <ul>
                                        <li *ngFor="let school of filteredSchools | async" [value]="school.schoolId"
                                            (click)="selectSchool(school)">{{school.name}}</li>
                                    </ul>
                                </div>
                            </span>
                        </li>
                    </ul>
                </div> -->
            </div>
            <div class="col-md-12 bg-white p-0">
                <div class="bg-white p-2">
                    <div class="headtitelar">Quick Resources to Support 5 Essentials</div>
                    <div class="box-div">
                        <div class="d-flex justify-content-center">
                            <div class="dflexmargin" *ngFor="let item of resources;let i = index">
                                <div class="bluebooardsub" style="cursor: pointer;" (click)="handleRedirect(item.redirectPath)">
                                    <div class="blueimgs">
                                        <img [src]="item.imageSrc">
                                    </div>
                                    <div class="text-center pb-2 bluewhite">{{item.displayText}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>